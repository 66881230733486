export const API_PATHS: any = {
    REGISTER: '/api/auth/signup',
    LOGIN: '/api/auth/signin',
    GET_BATTLE_TYPE: '/api/battles-type',
    CREATE_BATTLE: '/api/battles',
    GET_BATTLE: '/api/battles/{id}',
    GET_ALL_BATTLE: '/api/battles',
    SEND_ROOM_CODE: 'api/battles/shareRoomCode',
    UPDATE_BATTLE_RESPONSE: '/api/battles/updatePlayerResponse',
    ACCEPT_CHALLENGE: '/api/battles/challengeAccepted',
    UPDATE_BATTLE_STATUS: '/api/battles/updateStatus',
    CHANGE_BATTLE_STATUS: '/api/battles/changeStatus',
    CREATE_ACCOUNT_DETAILS: '/api/account-details',
    CREATE_WITHDRAWAL: '/api/withdraw',
    ADD_WALLET: '/api/wallet',
    GET_USER_WALLET_LOGS: '/api/wallet/logs',
    UPLOAD_FILES: '/api/files',
    UPDATE_USER: '/api/user/{id}',
    UPDATE_USER_AVATAR: '/api/user/update-user-avatar/{userId}/{avatarId}',
    KYC: '/api/kyc',
    UPDATE_KYC: '/api/kyc/{id}',
    FIND_KYC: '/api/kyc/find',
    GET_VISIBILITY_BY_MODULE: '/api/visibility/module/{name}',
    GET_TRANSACTION_HISTORY: '/api/transaction',
    AVATAR: '/api/avatar',
    GET_UPI_PAYMENT: 'api/upi-payment',
    GET_ACTIVE_UPI_PAYMENT: 'api/upi-payment/active',
    GET_PAYMENT_METHOD: 'api/payment-method',
    CREATE_PAYMENT_ORDER: 'api/transaction/create-upi-payment-order',
    GET_USER_REFERAL_DATA: 'api/user/{id}/get-user-referal-data',
    GET_PAYMENT_ORDER_STATUS: 'api/transaction/check-order-status',
};
