import React from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Routing from './components/Common/Routing/Routing';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { queryClientConfig } from './Config/queryClient';
import { UserProvider } from './Context/UserContext';
import { SocketProvider } from './Context/SocketContext';

type AppProps = {};

const App: React.FC<AppProps> = () => {
    return (
        <QueryClientProvider client={new QueryClient(queryClientConfig)}>
            <BrowserRouter>
                <ToastContainer
                    position="bottom-right"
                    autoClose={3000}
                    // hideProgressBar
                    newestOnTop
                    closeOnClick
                    pauseOnFocusLoss
                    pauseOnHover
                    theme="light"
                />
                <UserProvider>
                    <SocketProvider>
                        <Routing />
                    </SocketProvider>
                </UserProvider>
            </BrowserRouter>
        </QueryClientProvider>
    );
};

export default App;
