import { useState } from 'react';
import Layout from '../../components/Common/Layout/Layout';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const AddWallet = () => {
    const navigate = useNavigate();

    const [enterAmount, setEnterAmount] = useState('');

    const isValid = (enterAmount: string) => {
        if (parseInt(enterAmount) >= 50 && parseInt(enterAmount) <= 5000) {
            localStorage.setItem('addPayment', enterAmount);
            navigate(`/payment`);
        } else {
            toast('please enter valid values min 50 and max 5000', {
                type: 'error',
            });
        }
    };

    return (
        <Layout>
            <div className="flex flex-col items-center justify-center border rounded w-[90%] mx-auto shadow-2xl">
                <span className="w-full bg-[#F8F9FA] p-2 text-center border-b ">
                    Buy Chips
                </span>
                <div className="flex flex-col w-full gap-4 p-4">
                    <div className="flex border rounded">
                        <p className="bg-[#F8F9FA] w-[42px] border-r text-center p-2">
                            ₹
                        </p>
                        <input
                            type="text"
                            inputMode="numeric"
                            placeholder="Amount"
                            className="w-full h-[38px] focus:outline-none ml-2"
                            onChange={(e: any) =>
                                setEnterAmount(e.target.value.trim())
                            }
                        />
                    </div>
                    <button
                        className="bg-[#0d6efd] text-lg  text-[#fff]  rounded-lg p-2"
                        onClick={() => isValid(enterAmount)}
                    >
                        NEXT
                    </button>
                </div>
            </div>
        </Layout>
    );
};

export default AddWallet;
