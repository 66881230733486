import { useEffect, useState } from 'react';
import Layout from '../../components/Common/Layout/Layout';
import { useNavigate } from 'react-router-dom';
import { IoArrowBack } from 'react-icons/io5';
import { useCreateOrder } from '../../apis/hooks/usePaymentGateway';
import { useUserData } from '../../Context/UserContext';
import Loader from '../../components/Common/Loader/Loader';

const UpiPayment = () => {
    const { user } = useUserData();
    const navigate = useNavigate();
    const { mutate, isLoading } = useCreateOrder();
    const [paymentUrl, setPaymentUrl] = useState('');
    const amount = JSON.parse(localStorage.getItem('addPayment') || '');

    useEffect(() => {
        // Prepare payload and call createOrder on mount
        const createOrder = () => {
            const payload = {
                client_txn_id: Math.floor(
                    1000000000 + Math.random() * 9000000000
                ).toString(),
                amount: `${amount}`,
                p_info: 'Add to wallet',
                customer_name: user?.userdetails?.username,
                customer_id: user?.userdetails?.id,
                customer_email: 'jondoe@gmail.com',
                customer_mobile: user?.userdetails?.mobileNumber,
                redirect_url: 'https://mkgplayers.com/payment-status',
                udf1: 'user defined field 1 (max 25 char)',
                udf2: 'user defined field 2 (max 25 char)',
                udf3: 'user defined field 3 (max 25 char)',
            };

            mutate(payload, {
                onSuccess: (res: any) => {
                    setPaymentUrl(res?.data?.paymentUrl);
                },
            });
        };

        createOrder();
    }, []);

    // Redirect to payment URL when it's available
    useEffect(() => {
        if (paymentUrl) {
            window.location.href = paymentUrl;
        }
    }, [paymentUrl]);

    return (
        <Layout>
            <section className="mx-auto w-[90%]">
                <button
                    className="cursor-pointer flex gap-1 text-[#0d6efd] hover:text-white hover:bg-[#0d6efd] border border-[#0d6efd] items-center rounded-md leading-loose px-2"
                    onClick={() => navigate(-1)}
                >
                    <IoArrowBack />
                    Back
                </button>

                {isLoading && (
                    <div className="flex flex-col gap-8 justify-center text-center items-center text-2xl font-semibold p-2 my-2">
                        <p>Generating Payment Link. Please wait...</p>
                        <Loader scale={4} color="blue" />
                    </div>
                )}
            </section>
        </Layout>
    );
};

export default UpiPayment;
