import { SubmitHandler, useForm } from 'react-hook-form';
import useUserSignUp from '../../apis/hooks/useUserSignUp';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { useSignUp } from '../../apis/hooks/useAuth';
import Layout from '../Common/Layout/Layout';
import Loader from '../Common/Loader/Loader';

interface IFormInput {
    username: string;
    mobileNumber: number;
    registerUsingReferalCode: string;
    emailId: string;
}

const Register = () => {
    const navigate = useNavigate();
    const params = useParams();
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<IFormInput>({});
    const [username, setUsername] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    // const [emailId, setEmailId] = useState('');
    const [registerUsingReferalCode, setRegisterUsingReferalCode] = useState(
        params?.referCode ?? ''
    );

    const { mutate: userSignUp, isLoading } = useSignUp();

    const onSubmit = async () => {
        if (!username || !mobileNumber) {
            return toast('Please Enter all fields', { type: 'error' });
        }
        const payload = {
            username: username,
            mobileNumber: parseInt(mobileNumber),
            registerUsingReferalCode: registerUsingReferalCode ?? '',
            // emailId: emailId,
        };
        userSignUp(payload, {
            onSuccess: (res: any) => {
                if (res?.data?.error) {
                    toast(res?.data?.message ?? 'Unable to create user', {
                        type: 'error',
                    });
                }
                if (res?.data?.message) {
                    toast(res?.data?.message ?? 'OTP sent successfully', {
                        type: 'success',
                    });
                    localStorage.setItem('mobileNumber', mobileNumber);
                    // navigate(`/otp/${res?.data?.otp}`);
                    // navigate(`/otp/${res?.data?.emailId}`);
                    navigate(`/otp`);
                }
            },
            onError: (error: any) => {
                toast(error?.message ?? 'Error occured during User Creation', {
                    type: 'error',
                });
            },
        });
    };

    return (
        <Layout>
            <form
                className="w-[90%] border rounded mt-[20px] mx-auto"
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="off"
            >
                <div className="bg-[#F8F9FA] py-2 px-4 flex justify-center  border-b">
                    <span>Register</span>
                </div>
                <div className="p-4 mx-auto ">
                    <div>
                        <span className="mb-8">Name</span>
                        <input
                            type="text"
                            className={`border rounded p-2 w-full h-[38px] focus:outline-none ${errors.username ? 'border-red-600' : ''}`}
                            {...register('username', {
                                required: 'This is required.',
                                minLength: 2,
                                pattern: /^([a-zA-Z]+\s)*[a-zA-Z]+$/,
                                onChange: (e: any) =>
                                    setUsername(e.target.value.trim()),
                            })}
                        />
                        {errors.username && (
                            <span className="text-xs text-red-600">
                                {errors.username.message}
                            </span>
                        )}
                    </div>
                    <div className="my-2">
                        <span className="mb-8">Mobile Number</span>
                        <input
                            type="text"
                            inputMode="numeric"
                            className={`border rounded p-2 w-full h-[38px] focus:outline-none ${errors.mobileNumber ? 'border-red-600' : ''}`}
                            maxLength={10}
                            {...register('mobileNumber', {
                                required: 'This is required.',
                                minLength: {
                                    value: 2,
                                    message: 'Minimum length is 2 digits.',
                                },
                                maxLength: {
                                    value: 10,
                                    message: 'Maximum length is 10 digits.',
                                },
                                pattern: {
                                    value: /^[0-9]{2,10}$/,
                                    message: 'Only numeric values are allowed.',
                                },
                                onChange: (e) =>
                                    setMobileNumber(e.target.value.trim()),
                            })}
                        />
                        {errors.mobileNumber && (
                            <span className="text-xs text-red-600">
                                {errors.mobileNumber.message}
                            </span>
                        )}
                    </div>
                    {/* <div className="my-2">
                        <span className="mb-8">Email Id</span>
                        <input
                            type="text"
                            className={`border rounded p-2 w-full h-[38px] focus:outline-none ${errors.emailId ? 'border-red-600' : ''}`}
                            {...register('emailId', {
                                required: 'Email Id is Required.',
                                pattern: {
                                    value: /^[a-zA-Z0-9._%+-]+@gmail\.com$/,
                                    message:
                                        'Please enter a valid Gmail address.',
                                },
                                onChange: (e: any) =>
                                    setEmailId(e.target.value.trim()),
                            })}
                        />
                        {errors.emailId && (
                            <span className="text-xs text-red-600">
                                {errors.emailId.message}
                            </span>
                        )}
                    </div> */}
                    <div>
                        <span className="mb-8">Refer Code (Optional)</span>
                        <input
                            type="text"
                            disabled={!!params?.referCode}
                            defaultValue={params?.referCode ?? ''}
                            className="border rounded p-2 w-full h-[38px] focus:outline-none"
                            {...register('registerUsingReferalCode', {
                                onChange: (e: any) =>
                                    setRegisterUsingReferalCode(
                                        e.target.value.trim()?.toUpperCase()
                                    ),
                            })}
                        />
                    </div>
                    <div className="mt-4">
                        <span className="text-center text-[12.8px] mb-4 font-normal">
                            By Continuing, you agree to our{' '}
                            <u className="text-indigo-600 "> Legal Terms </u>{' '}
                            and you are 18 years or older.
                        </span>
                        <button
                            type="submit"
                            disabled={isLoading}
                            className="w-full bg-indigo-600 text-[#fff] h-[38px] rounded  mt-4"
                        >
                            <div className="flex items-center justify-center gap-4">
                                {isLoading ? 'Sending OTP...' : 'Register'}
                                {isLoading && (
                                    <Loader scale={2} color="white" />
                                )}
                            </div>
                        </button>
                        <span className="flex justify-center text-center text-[12.8px] py-2 font-normal cursor-pointer">
                            Already have an account ?{' '}
                            <u
                                className="ml-2 text-indigo-600"
                                onClick={() => navigate('/login')}
                            >
                                {' '}
                                Login{' '}
                            </u>
                        </span>
                    </div>
                </div>
            </form>
        </Layout>
    );
};

export default Register;
