export const queryClientConfig = {
    defaultOptions: {
        queries: {
            retry: false, // Query will not be retried on failure.
            staleTime: 0,
            cacheTime: 0,
            refetchOnWindowFocus: false, // Query will not be refetched on window focus.
        },
        mutations: {
            retry: false, // Mutation will not be retried on failure.
        },
    },
};
