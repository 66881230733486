import { useMutation } from '@tanstack/react-query';
import { Client } from '..';
import { API_PATHS } from '../constants';
import { toast } from 'react-toastify';

export const useCreateWithdrawal = () => {
    const { isLoading, mutate } = useMutation(
        ['create-withdrawal'],
        (data: any) => {
            return Client.post(API_PATHS.CREATE_WITHDRAWAL, data);
        },
        {
            onError: async (error: any, variables: any, context: any) => {},
        }
    );
    return {
        isLoading,
        mutate,
    };
};
