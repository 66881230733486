import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGetPaymentOrderStatus } from '../../apis/hooks/usePaymentGateway';
import { IoArrowBack } from 'react-icons/io5';
import Layout from '../../components/Common/Layout/Layout';
import Loader from '../../components/Common/Loader/Loader';
import { MdOutlineDoneAll } from 'react-icons/md';
import { GiCancel } from 'react-icons/gi';

const PaymentStatus = () => {
    const [searchParams] = useSearchParams();
    const client_txn_id = searchParams.get('client_txn_id');
    const txnId = searchParams.get('txn_id');
    const { mutateAsync, isLoading } = useGetPaymentOrderStatus();
    console.log('client_txn_id:', client_txn_id);
    console.log('txn_id:', txnId);
    const [paymentStatus, setPaymentStatus] = useState<any>();

    useEffect(() => {
        (async () => {
            const data: any = await mutateAsync({
                client_txn_id,
                txn_date: new Date()
                    .toLocaleDateString('en-GB')
                    .replace(/\//g, '-'), // in DD-MM-YYYY format
            });

            setPaymentStatus(data?.data?.data);

            console.log('data', data);
        })();
    }, [client_txn_id]);
    return (
        <Layout>
            <section className="mx-auto w-[90%]">
                {/* <button
                    className=" cursor-pointer flex gap-1 text-[#0d6efd] hover:text-white hover:bg-[#0d6efd]  border border-[#0d6efd] items-center rounded-md leading-loose px-2"
                    onClick={() => navigate(-1)}
                >
                    <IoArrowBack />
                    Back
                </button> */}

                {isLoading ? (
                    <div className="flex flex-col gap-8 justify-center text-center items-center text-2xl font-semibold p-2 my-2">
                        <p>Fetching Payment Results</p>
                        <Loader scale={4} color="blue" />
                    </div>
                ) : paymentStatus?.status === 'failure' ? (
                    <div className="flex gap-2 p-2 font-semibold border border-[#0000002d] rounded-md shadow-xl my-2 text-center text-xl items-center">
                        <GiCancel size={40} color="red" />
                        <p className="">
                            {paymentStatus?.remark ?? 'Transaction Not done'}
                        </p>
                    </div>
                ) : (
                    <div className="flex gap-2 p-2 font-semibold border border-[#0000002d] rounded-md shadow-xl my-2">
                        <MdOutlineDoneAll size={40} color="red" />
                        <p className="">Your Payment is successfully done</p>
                    </div>
                )}

                {/* <div className="flex gap-2 p-2 font-semibold border border-[#0000002d] rounded-md shadow-xl my-2">
                    <MdOutlineDoneAll size={40} color="red" />
                    <p className="">Your Payment is successfully done</p>
                </div> */}
            </section>
        </Layout>
    );
};

export default PaymentStatus;
