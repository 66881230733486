import React, { useEffect, useRef, useState } from 'react';
import Layout from '../../components/Common/Layout/Layout';
import { useNavigate } from 'react-router-dom';
import AlertSection from '../../components/Common/AlertSection/AlertSection';
import { useUserData } from '../../Context/UserContext';
import { useUploadFiles } from '../../apis/hooks/useUploadFiles';
import {
    useAddWallet,
    useGetActiveUPIPayment,
} from '../../apis/hooks/useWallet';
import { toast } from 'react-toastify';
import { MdContentCopy } from 'react-icons/md';
import upiImage from '../../assets/upi.png';
import UploadImg from '../../assets/UploadImg';
import Loader from '../../components/Common/Loader/Loader';
import { TiDeleteOutline } from 'react-icons/ti';
import { TRANSACTION_TYPE } from '../../Common/constants';

const QRPayment = () => {
    const navigate = useNavigate();
    const fileInputRef = useRef<any>(null);
    const amount = JSON.parse(localStorage.getItem('addPayment') || '0');

    const [utr_number, setUtr_number] = useState('');
    const [fileData, setFileData] = useState<any>(null);

    const { user } = useUserData();
    const { data: upiPaymentData, isLoading: fetchingUpiPayment } =
        useGetActiveUPIPayment();
    const { mutate: uploadFile, isLoading: uploading } = useUploadFiles();
    const { mutate: addWallet, isLoading: walletSubmitting } = useAddWallet();

    const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const fileToUpload = e.target.files?.[0];

        if (fileToUpload) {
            const formData = new FormData();
            formData.append('file', fileToUpload);

            await uploadFile(formData, {
                onSuccess: (data: any) => {
                    if (data?.data?.id) {
                        setFileData(data.data);
                    } else {
                        toast(data?.message ?? 'Error while uploading file', {
                            type: 'error',
                        });
                        resetFileInput();
                    }
                },
                onError: () => {
                    resetFileInput();
                    toast('Something went wrong', { type: 'error' });
                },
            });
        }
    };

    const resetFileInput = () => {
        fileInputRef.current.value = '';
    };

    const onADD = async () => {
        if (amount && utr_number && fileData?.id) {
            const payload = {
                userId: user?.userdetails?.id,
                amount: parseInt(amount),
                type: TRANSACTION_TYPE.CREDIT,
                fileId: fileData.id,
                utr_number,
            };

            addWallet(payload, {
                onSuccess: (data: any) => {
                    if (data?.data?.success) {
                        toast(
                            data?.data?.message ?? 'Request added successfully',
                            { type: 'success' }
                        );
                        navigate('/home');
                    }
                },
                onError: (error: any) => {
                    toast(error?.message ?? 'Something went wrong', {
                        type: 'error',
                    });
                },
            });
        } else {
            if (!amount) {
                toast('Please add chips', { type: 'error' });
            } else if (!utr_number) {
                toast('Please enter UTR Number', { type: 'error' });
            } else if (!fileData?.id) {
                toast('Please add screenschot', { type: 'error' });
            }
        }
    };

    const PaymentInfo = () => {
        return (
            <>
                <div
                    className="cursor-pointer p-2 flex justify-center items-center border gap-2 border-[#757575] rounded-lg mb-4 font-semibold"
                    onClick={() => {
                        navigator.clipboard.writeText(upiPaymentData?.upiId);
                        toast('Code Copied', { type: 'success' });
                    }}
                >
                    <img src={upiImage} className="h-12" alt="upi" />
                    <span>{upiPaymentData?.upiId}</span>
                    <MdContentCopy />
                </div>
                <div className="flex flex-col gap-4">
                    {/* <p className="font-bold">Account Name:</p>
                    <p>{upiPaymentData?.name}</p>
                    <p className="font-bold">UPI ID:</p>
                    <p>{upiPaymentData?.upiId}</p> */}
                    <img src={upiPaymentData?.file?.fullUrl} alt="qr" />
                </div>
            </>
        );
    };

    const UTRInput = () => (
        <div className="flex flex-col gap-2 py-2">
            <div>
                <span>Enter Amount</span>
                <div className="flex border rounded">
                    <span className="bg-[#F8F9FA] border-r text-center py-2 px-4">
                        ₹
                    </span>
                    <input
                        type="tel"
                        placeholder="Amount"
                        value={amount}
                        className="p-2 ml-2"
                        disabled
                    />
                </div>
            </div>
            <div>
                <span>Enter UTR Number</span>
                <div className="flex border rounded">
                    <span className="bg-[#F8F9FA] border-r text-center py-2 px-4">
                        ₹
                    </span>
                    <input
                        type="tel"
                        placeholder="Enter UTR Number"
                        value={utr_number}
                        className="w-full p-2 ml-2"
                        minLength={12}
                        maxLength={12}
                        onChange={(e) => setUtr_number(e.target.value?.trim())}
                    />
                </div>
            </div>
        </div>
    );

    const LoaderSection = () => (
        <div className="flex flex-col items-center gap-4">
            <Loader scale={3} />
            <p className="text-xl font-semibold text-gray-400"> Loading...</p>
        </div>
    );

    return (
        <Layout>
            <section className="w-[90%] mx-auto">
                <div className="flex flex-col gap-2 p-1 mb-2">
                    <AlertSection
                        className="p-2 text-xs bg-gray-300"
                        message={
                            'अगर कोई यूजर पैसा add करते टाइम किसी भी तरह कि फ्रॉड बाजी करता है तो, या फिर बार बार गलत Screen shot (UTR Number) डालता है तो उसकी ID block कर दी जाएगी'
                        }
                        alertType="INFO"
                    />
                    <AlertSection
                        className="p-2 text-xs bg-yellow-300"
                        message={
                            'यूजर Deposit करते समय ध्यान दे जो प्रजेंट में UPI ID लगी हुई है इस पर पेमेंट करें अन्यथा अपडेट नहीं होगी !'
                        }
                        alertType="WARNING"
                    />
                    <AlertSection
                        className="p-2 text-xs bg-gray-300"
                        message={
                            "अगर किसी यूज़र को 5 हजार RS से जायदा add करने हो या फ़िर लगे हुए (QR Code - UPI  I'd) में नही डाल पा रहा है तो वो Whatsapp पर मैसेज कर के add करा सकता है,"
                        }
                        alertType="INFO"
                    />
                </div>

                {fetchingUpiPayment ? (
                    <div className="flex items-center justify-center p-2">
                        <LoaderSection />
                    </div>
                ) : upiPaymentData?.id ? (
                    <PaymentInfo />
                ) : (
                    <div className="flex items-center justify-center p-4 text-red-600">
                        No UPI Found
                    </div>
                )}

                {UTRInput()}

                {!fileData ? (
                    <>
                        <div
                            className="p-2 flex justify-center items-center border gap-2 border-[#757575] rounded bg-slate-300 cursor-pointer"
                            onClick={() => fileInputRef.current?.click()}
                        >
                            <UploadImg />
                            <div className="flex gap-2 ">
                                {uploading ? (
                                    <div className="flex gap-4">
                                        <p>Uploading...</p>
                                        <Loader scale={2.7} />
                                    </div>
                                ) : (
                                    'Upload your Screen Shot'
                                )}
                            </div>
                        </div>
                        <input
                            ref={fileInputRef}
                            className="hidden"
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleFileUpload(e)}
                            required
                            data-max-size="4096000"
                            id="upload_ipt"
                        />
                    </>
                ) : (
                    <div className="">
                        <div className="relative flex justify-center p-2">
                            <img
                                src={fileData?.fullUrl}
                                width={300}
                                alt="upload-file"
                            />
                            <TiDeleteOutline
                                className="absolute top-[-20px] cursor-pointer right-2"
                                size={28}
                                color="red"
                                onClick={() => setFileData(null)}
                            />
                        </div>
                    </div>
                )}

                <div className="my-4">
                    <button
                        disabled={
                            walletSubmitting || !amount
                            // !utr_number ||
                            // !fileData
                        }
                        className="rounded-lg p-2 w-full text-[#fff] bg-[#198754] border-[#198754] hover:bg-[#157347] hover:border-[#146c43] cursor-pointer"
                        onClick={onADD}
                    >
                        <div className="flex items-center justify-center gap-4">
                            {walletSubmitting ? 'Loading...' : 'Submit'}
                            {walletSubmitting && (
                                <Loader scale={2} color="white" />
                            )}
                        </div>
                    </button>
                </div>
            </section>
        </Layout>
    );
};

export default QRPayment;
