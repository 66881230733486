import { createContext, useContext, useEffect, useState } from 'react';
import useUser from '../apis/hooks/useUser';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export const UserContext = createContext<any>(null);

export const useUserData = () => {
    const { user, setUser, fetchUser } = useContext(UserContext);
    return { user, setUser, fetchUser };
};

export const UserProvider = (props: any) => {
    const { mutate: getUser } = useUser();
    const isLoggedIn = !!localStorage.getItem('user');
    const navigate = useNavigate();

    const [user, setUser] = useState(() => {
        // Get initial state from localStorage
        const storedUser = localStorage.getItem('user');
        return storedUser ? JSON.parse(storedUser) : null;
    });

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user') ?? '{}');

        if (storedUser && isLoggedIn) {
            getUser(
                { id: storedUser?.userdetails?.id },
                {
                    onSuccess: (res: any) => {
                        if (res?.status === 200) {
                            setUser({ userdetails: res?.data });
                        }
                    },
                    onError: (error: any) => {
                        toast(error?.message ?? 'Something went wrong', {
                            type: 'error',
                        });
                        // Clear all items from localStorage
                        localStorage.clear();
                        setUser(null);
                        navigate('/login');
                    },
                }
            );
        }
    }, []);

    const fetchUser = () => {
        const storedUser = JSON.parse(localStorage.getItem('user') ?? '{}');

        if (storedUser && isLoggedIn) {
            getUser(
                { id: storedUser?.userdetails?.id },
                {
                    onSuccess: (res: any) => {
                        if (res?.status === 200) {
                            setUser({ userdetails: res?.data });
                        }
                    },
                    onError: (error: any) => {},
                }
            );
        }
    };

    // useEffect(() => {
    //     fetchUser();
    // }, []);

    useEffect(() => {
        // Update localStorage whenever user state changes
        if (user) {
            localStorage.setItem('user', JSON.stringify(user));
        } else {
            localStorage.removeItem('user');
        }
    }, [user]);

    return (
        <UserContext.Provider value={{ user, setUser, fetchUser }}>
            {props.children}
        </UserContext.Provider>
    );
};
