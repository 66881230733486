import { useMutation, useQuery } from '@tanstack/react-query';
import { API_PATHS } from '../constants';
import { Client } from '..';

export const useSubmitKYC = ({ kycId }: { kycId: boolean }) => {
    const { data, isLoading, mutate, error } = useMutation(
        ['submit-kyc'],
        (data: any) => {
            if (kycId) {
                return Client.patch(
                    API_PATHS.UPDATE_KYC.replace('{id}', kycId),
                    data
                );
            } else {
                return Client.post(API_PATHS.KYC, data);
            }
        }
    );

    return {
        data: data,
        isLoading,
        mutate,
        error,
    };
};

export const useGetKyc = ({ userId }: { userId: string }) => {
    const { data, isLoading, error } = useQuery(
        ['get-kyc'],
        async (data: any) => {
            return await Client.get(API_PATHS.FIND_KYC, {
                params: {
                    userId: userId,
                },
            });
        },
        {
            enabled: !!userId,
        }
    );

    return {
        data: data?.data?.data ?? null,
        isLoading,
        error,
    };
};
