import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LeftBlueArror from '../../assets/LeftBlueArror';
import { toast } from 'react-toastify';
import useUser from '../../apis/hooks/useUser';
import { UserContext, useUserData } from '../../Context/UserContext';
import useValidateOtp from '../../apis/hooks/useValidateOtp';
import Layout from '../Common/Layout/Layout';
import Loader from '../Common/Loader/Loader';
import { useSignIn } from '../../apis/hooks/useAuth';

const OTP = () => {
    const navigate = useNavigate();
    const numInputs = 6;

    const [otp, setOtp] = useState(new Array(numInputs).fill(''));
    const [activeOtpField, setActiveOtpField] = useState(false);
    const [wrongOTP, setWrongOTP] = useState<boolean>(false);
    const [otpState, setOtpState] = useState<boolean>();
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
    const { mutate: validateOtp, isLoading } = useValidateOtp();
    const { mutate: sendOtp, isLoading: isResendingOtp } = useSignIn();
    const { user, setUser } = useUserData();

    const [timeLeft, setTimeLeft] = useState(30);
    const [showResendButton, setShowResendButton] = useState(false);

    useEffect(() => {
        // Initialize the refs array
        inputRefs.current = Array(numInputs)
            .fill(null)
            .map(
                (_, i) =>
                    inputRefs.current[i] ||
                    React.createRef<HTMLInputElement>().current
            );
    }, [numInputs]);

    useEffect(() => {
        let timerId: NodeJS.Timeout;
        if (timeLeft > 0) {
            timerId = setTimeout(() => {
                setTimeLeft(timeLeft - 1);
            }, 1000);
        } else {
            setShowResendButton(true);
        }
        return () => clearTimeout(timerId);
    }, [timeLeft]);

    const handleResendOtp = () => {
        const mobileNo = localStorage.getItem('mobileNumber') || '';
        const payload = {
            mobileNumber: parseInt(mobileNo),
        };

        if (!payload.mobileNumber) {
            toast('Please enter mobile number', { type: 'error' });
            return;
        }

        if (payload.mobileNumber.toString().trim().length !== 10) {
            toast('Please enter valid mobile number', { type: 'error' });
            return;
        }

        sendOtp(payload, {
            onSuccess: (res: any) => {
                if (res?.data?.error) {
                    toast(res?.data?.message ?? 'Failed to send OTP', {
                        type: 'error',
                    });
                }
                if (res?.data?.message) {
                    toast('OTP sent successfully', { type: 'success' });
                    setTimeLeft(30);
                    setShowResendButton(false);
                }
            },
            onError: (err: any) => {
                toast(err?.message ?? 'Failed to send OTP', { type: 'error' });
            },
        });
    };

    const handleChange = (index: any, event: any) => {
        const value = event.target.value;
        if (isNaN(Number(value))) {
            return;
        }

        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        if (
            value !== '' &&
            index < numInputs - 1 &&
            inputRefs.current[index + 1]
        ) {
            inputRefs.current[index + 1]?.focus();
            setWrongOTP(false);
        }

        if (index === numInputs - 1 && value) {
            inputRefs.current[index]?.blur();
        }
    };

    const handleKeyDown = (
        index: number,
        event: React.KeyboardEvent<HTMLInputElement>
    ) => {
        if (event.key === 'Backspace' && index > 0 && otp[index] === '') {
            inputRefs.current[index - 1]?.focus();
        }
    };

    const onSubmit = async () => {
        const mobileNo = localStorage.getItem('mobileNumber') || '';
        const combined_String = otp.join(' ');
        const final_OTP = combined_String.replace(/\s/g, '');

        const payload = {
            mobileNumber: mobileNo,
            otp: parseInt(final_OTP),
        };

        if (!payload.mobileNumber) {
            toast('Please enter mobile number', { type: 'error' });
            return;
        }

        if (payload.mobileNumber.toString().trim().length !== 10) {
            toast('Please enter valid mobile number', { type: 'error' });
            return;
        }

        validateOtp(payload, {
            onSuccess: (res: any) => {
                if (res?.data?.access_token) {
                    localStorage.setItem('TOKEN', res?.data?.access_token);
                    localStorage.setItem(
                        'user',
                        JSON.stringify({
                            userdetails: res?.data?.userdetails,
                            walletdetails: res?.data?.walletdetails,
                        })
                    );
                    setUser(res?.data);
                    toast('Login Successful', { type: 'success' });
                    navigate('/home');
                } else {
                    toast('Login Failed', { type: 'error' });
                }
            },
            onError: (err: any) => {
                setOtpState(false);
                setWrongOTP(true);
                toast(err?.message ?? 'OTP Validation Failed', {
                    type: 'error',
                });
            },
        });
    };

    const renderOtpInputBoxes = () => {
        return (
            <>
                <div className={' flex justify-center '}>
                    {Array.from({ length: numInputs }).map((_, index) => (
                        <input
                            key={index}
                            ref={(el) => (inputRefs.current[index] = el)}
                            type="text"
                            inputMode="numeric"
                            value={otp[index]}
                            maxLength={1}
                            onClick={() => setActiveOtpField(true)}
                            onChange={(e) => handleChange(index, e)}
                            onKeyDown={(e) => handleKeyDown(index, e)}
                            autoFocus={index === 0}
                            className={`border border-solid border-[#B7B7B7] rounded w-[40px] sm:w-[48px] h-[40px] sm:h-[48px] mx-1 my-1 font-semibold text-gray-900 text-center focus:border-[#5A4BDA] ${
                                wrongOTP
                                    ? 'border-[red]'
                                    : otpState &&
                                        inputRefs.current[index]?.value !== ''
                                      ? 'border-[green]'
                                      : inputRefs.current[index]?.value ===
                                            '' && 'border-black'
                            }`}
                        />
                    ))}
                </div>
            </>
        );
    };

    return (
        <Layout>
            <section className="m-auto w-[90%] border rounded  mt-[20px]">
                <div className="bg-[#F8F9FA] py-2 px-4 flex flex-col items-center justify-center border-b">
                    <p>Enter OTP</p>
                </div>
                <span
                    className="text-center text-[12.8px] mt-4 ml-4 font-normal cursor-pointer flex  text-indigo-600 p-2 border border-indigo-600 w-[150px] rounded-md"
                    onClick={() => navigate('/login')}
                >
                    <LeftBlueArror className="w-5 h-5 my-auto" /> Change Number
                </span>
                <div className="p-4 mx-auto ">
                    {renderOtpInputBoxes()}
                    <div className="mt-4 text-sm">
                        {!showResendButton ? (
                            <p className="text-center text-gray-700">
                                Resend OTP in {timeLeft} seconds
                            </p>
                        ) : (
                            <div className="flex justify-end mt-4 text-sm">
                                <button
                                    disabled={isResendingOtp}
                                    onClick={handleResendOtp}
                                    className="p-1 border border-gray-700 rounded-md"
                                >
                                    {isResendingOtp ? (
                                        <div className="flex items-center justify-center gap-2 p-1">
                                            <Loader scale={1} color="black" />
                                            {/* <p>Resending...</p> */}
                                        </div>
                                    ) : (
                                        'Resend'
                                    )}
                                </button>
                            </div>
                        )}
                    </div>
                    <div>
                        <span className="text-center text-[12.8px] mb-4 font-normal mx-auto mt-2 block">
                            By Continuing, you agree to our{' '}
                            <u className="text-indigo-600 ">Legal Terms</u> and
                            you are 18 years or older.
                        </span>
                        <button
                            disabled={isLoading || otp.join('').length < 6}
                            className={`w-full bg-indigo-600 text-[#fff] h-[38px] rounded mt-2 ${otp.join('').length < 6 ? 'opacity-50' : ''}`}
                            onClick={() => onSubmit()}
                        >
                            {isLoading ? (
                                <div className="flex items-center justify-center gap-2 p-2">
                                    <Loader scale={2} color="white" />
                                    <p className="text-white">Loading...</p>
                                </div>
                            ) : (
                                'Login'
                            )}
                        </button>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default OTP;
