import { useMutation, useQuery } from '@tanstack/react-query';
import { API_PATHS } from '../constants';
import { Client } from '..';
import { useEffect } from 'react';

export const useGetAvatars = () => {
    const { data, isLoading, error } = useQuery(
        ['get-all-avatar'],
        async () => {
            return await Client.get(API_PATHS.AVATAR);
        }
    );

    return {
        data: data?.data?.data ?? null,
        isLoading,
        error,
    };
};
