import Layout from '../../components/Common/Layout/Layout';
import WhatsappIcon from '../../assets/WhatsappIcon';
import Telegram from '../../assets/Telegram';
import { toast } from 'react-toastify';
import chat from '../../assets/comment.png';
import insta from '../../assets/instagram.png';
const buttonType = [
    {
        key: 'Whatsapp',
        type: 'Share to Whatsapp',
    },
    {
        key: 'Telegram',
        type: 'Share to Telegram',
    },
    {
        key: 'Live',
        type: 'Live Chat',
    },
    {
        key: 'Instagram',
        type: 'Connect on Instagram',
    },
];

const Support = () => {
    const handleResponse = (type: string) => {
        switch (type) {
            case 'Whatsapp':
                return window.open('https://t.ly/6ydoQ');
            case 'Telegram':
                // return window.open('https://telegram.org/');
                return toast('Comming soon', { type: 'success' });
            case 'Instagram':
                // return window.open('https://Instagram.com/m_munkad');
                return window.open(
                    'https://www.instagram.com/invites/contact/?igsh=1s002p3f61f77&utm_content=v9nz0kd'
                );
            case 'Live':
                return toast('Comming soon', { type: 'success' });
        }
    };

    const handleCss = (type: string) => {
        switch (type) {
            case 'Whatsapp':
                return 'bg-green-800 hover:bg-green-300 ';
            case 'Live':
                return 'bg-[#0d6efd] hover:bg-[#a1bce6]';
            case 'Instagram':
                return 'bg-gradient-to-r from-[#833ab4] via-[#fd1d1d] to-[#fcb045] hover:bg-[#757575]';
            case 'Telegram':
                return 'bg-[#000] hover:bg-[#757575]';
        }
    };

    return (
        <Layout>
            <div className="p-4 ">
                <img
                    src="	https://gotkingimages.s3.ap-southeast-2.amazonaws.com/images/contact.webp"
                    className="px-4 mx-auto"
                />
                <p className="text-[22px] text-center">(Monday to Saturday)</p>
                <p className="text-[22px] text-center mb-10">
                    Supporting time 11:00 9:00
                </p>
                {buttonType.map((item: any) => {
                    return (
                        <button
                            className={` ${handleCss(item?.key)} flex  justify-center items-center gap-2 text-[#fff] w-full rounded h-[42px] mt-2`}
                            onClick={() => handleResponse(item?.key)}
                        >
                            {item?.key === 'Whatsapp' ? (
                                <WhatsappIcon className="w-[24px] h-[24px]" />
                            ) : item?.key === 'Telegram' ? (
                                <Telegram />
                            ) : item?.key === 'Live' ? (
                                <img src={chat} className="w-4 " />
                            ) : (
                                <img src={insta} className="w-4 h-4" />
                            )}

                            {item?.type}
                        </button>
                    );
                })}
            </div>
        </Layout>
    );
};

export default Support;
