import { Client } from '../index';
import { API_PATHS } from '../constants';
import { useMutation } from '@tanstack/react-query';

export const useCreateBattle = () => {
    const { isLoading, mutate } = useMutation(
        [`create-battle`],
        (data: any) => {
            return Client.post(API_PATHS.CREATE_BATTLE, data);
        }
    );
    return {
        isLoading,
        mutate,
    };
};
export const useUpdatePlayerBattleResponse = () => {
    const { isLoading, mutate } = useMutation(
        [`update-battles-status`],
        (data: any) => {
            return Client.post(API_PATHS.UPDATE_BATTLE_RESPONSE, data);
        }
    );
    return {
        isLoading,
        mutate,
    };
};

export const useAcceptChallenge = () => {
    const { isLoading, mutate } = useMutation(
        [`accept-battle-challenge`],
        (data: any) => {
            return Client.post(API_PATHS.ACCEPT_CHALLENGE, data);
        }
    );
    return {
        isLoading,
        mutate,
    };
};

export const useChangeBattleStatus = () => {
    const { isLoading, mutate } = useMutation(
        [`change-battle-status`],
        (data: any) => {
            return Client.post(API_PATHS.CHANGE_BATTLE_STATUS, data);
        }
    );
    return {
        isLoading,
        mutate,
    };
};
