// PlayArea.tsx
// import { useContext, useEffect, useState } from 'react';
import Layout from '../../components/Common/Layout/Layout';
import { useSocket } from '../../Context/SocketContext';
import PlayBox from '../../components/PlayBox/PlayBox';
// import { io } from 'socket.io-client';

const PlayArea = () => {
    const { message } = useSocket();
    // const [socket, setSocket] = useState<any>(null);
    // const [message, setMessage] = useState<any>();
    // useEffect(() => {
    //     const url: string =
    //         process.env.REACT_APP_API_SOCKET_CONNECTION ||
    //         // 'https://starfish-app-cnmgd.ondigitalocean.app';
    //         'https://oyster-app-psc6w.ondigitalocean.app';

    //     console.log(`Socket URL: ${url}`);
    //     const socketInstance = io(url, {
    //         transports: ['websocket', 'polling'],
    //         reconnection: true,
    //         reconnectionAttempts: 5,
    //         reconnectionDelay: 1000,
    //     });

    //     const handleMessage = (newMessage: any) => {
    //         console.log('New message received:', newMessage);
    //         setMessage(newMessage);
    //     };

    //     socketInstance.on('connect', () => {
    //         console.log('Socket connected:', socketInstance.id);
    //     });

    //     socketInstance.on('onMessage', handleMessage);
    //     setSocket(socketInstance);

    //     return () => {
    //         socketInstance.off('onMessage', handleMessage);
    //         socketInstance.disconnect();
    //     };
    // }, []);

    console.log('Play Area Message:', message);

    return (
        <Layout>
            {/* Render your component logic here */}
            <PlayBox message={message} />
            {/* <PlayBox message={message} /> */}
        </Layout>
    );
};

export default PlayArea;
