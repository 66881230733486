import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { Transition, Dialog } from '@headlessui/react';
import { useUserData } from '../../Context/UserContext';
import vsImg from '../../assets/VS.webp';
import User from '../../assets/UserProfile';
import { Tooltip } from 'react-tooltip';

type DialogModalProps = {
    onClose: () => void;
    isOpen: boolean;
    battleData: any;
    // myResult: any;
};

const ViewBattleDetails = (props: DialogModalProps) => {
    const { isOpen, onClose, battleData } = props;
    const { user } = useUserData();

    const renderCompletedBattle = () => {
        return (
            <div className="p-2 font-semibold transition ease-in-out transform border rounded-md">
                <div className="flex items-center justify-between">
                    {/* Challenger section */}
                    <div
                        className="flex items-center gap-2 cursor-pointer"
                        data-tooltip-id="challengerUsername"
                        data-tooltip-content={battleData?.challenger?.username}
                    >
                        <User className="w-[28px] h-[28px] rounded-xl border" />
                        <p className="w-[60px]">
                            {battleData?.challenger?.username?.slice(0, 5) +
                                '...'}
                        </p>
                    </div>

                    {/* VS image and amount section */}
                    <div className="flex items-center">
                        <img
                            src={vsImg}
                            className="w-[30px] h-10"
                            alt="vsImg"
                        />
                    </div>

                    {/* Acceptor section */}
                    <div
                        className="flex items-center gap-2 cursor-pointer"
                        data-tooltip-id="acceptorUsername"
                        data-tooltip-content={battleData?.acceptor?.username}
                    >
                        <p className="w-[60px]">
                            {battleData?.acceptor?.username?.slice(0, 5) +
                                '...'}
                        </p>
                        <User className="w-[28px] h-[28px] rounded-xl border" />
                    </div>
                </div>
                <div className="flex justify-center">
                    <span className="font-bold text-[#198754] pt-2">
                        Rs {battleData?.amount}
                    </span>
                </div>

                {/* Tooltip for challenger and acceptor usernames */}
                <Tooltip id="challengerUsername" />
                <Tooltip id="acceptorUsername" />
            </div>
        );
    };

    return (
        <Transition.Root show={isOpen} as={React.Fragment} appear>
            <Dialog as="div" className="relative z-[1000]" onClose={onClose}>
                <Transition.Child
                    as={React.Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bottom-0 transition-opacity bg-black bg-opacity-50" />
                </Transition.Child>
                <div className="fixed inset-0 z-10 flex items-end justify-center overflow-hidden">
                    <Transition.Child
                        as={React.Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-full"
                        enterTo="opacity-100 translate-y-0"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-full"
                    >
                        <Dialog.Panel className="w-full max-w-lg overflow-hidden transition-all transform bg-white shadow-2xl">
                            <div className="overflow-y-auto max-h-[50vh] bg-white p-4 flex flex-col gap-4">
                                <div className="flex items-center justify-between text-center text-lg text-[#212529] bg-white">
                                    <div className="flex flex-col gap-1">
                                        <div className="flex gap-2">
                                            <h1 className="font-semibold">
                                                Room Code :
                                            </h1>
                                            <h1>
                                                {battleData?.roomCode ?? ''}
                                            </h1>
                                        </div>

                                        <div className="flex gap-2">
                                            <h1 className="font-semibold">
                                                Your Result :
                                            </h1>
                                            <h1>
                                                I{' '}
                                                {battleData?.playerResponse ??
                                                    ''}
                                            </h1>
                                        </div>
                                    </div>

                                    <button onClick={onClose} className="">
                                        &#10005;
                                    </button>
                                </div>

                                {renderCompletedBattle()}

                                <div className="text-xs text-center">
                                    <p>
                                        Your Game Result is Successfully Posted.
                                        Please allow us 2-5 minutes to review
                                        and update your game. If you have Pasted
                                        Wrong Result or Screenshot, kindly
                                        Contact us.
                                    </p>
                                </div>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default ViewBattleDetails;
