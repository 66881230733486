import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import useCreateAccountDetails from '../../apis/hooks/useWithdrawalPostDetails';
import { useUserData } from '../../Context/UserContext';
import Loader from '../Common/Loader/Loader';
import { useNavigate } from 'react-router-dom';

interface WithdrawalUPIForm {
    accountName: string;
    upiId: string;
    amount: string;
}

const WithdrawalUPI = ({ data }: { data?: any }) => {
    const { user } = useUserData();
    const navigate = useNavigate();

    // React Hook Form for form state management
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm<WithdrawalUPIForm>({
        defaultValues: {
            accountName: data?.accountName ?? '',
            upiId: data?.upiId ?? '',
            amount: '',
        },
    });

    const { mutate: submitUPI, isLoading } = useCreateAccountDetails();

    const onSubmit = (formData: WithdrawalUPIForm) => {
        const submitData = {
            primaryChoice: 'UPI',
            upiId: formData.upiId.trim(),
            accountName: formData.accountName,
            userId: user?.userdetails?.id,
            amount: +formData.amount,
        };

        submitUPI(submitData, {
            onSuccess: (res: any) => {
                if (res?.error) {
                    toast(
                        Array.isArray(res?.message)
                            ? res?.message[0]
                            : 'Details not Submitted',
                        { type: 'error' }
                    );
                } else if (res?.data?.message) {
                    navigate('/battle/Classic/b744r8hyegddoqfng6r9z2hzz');
                    toast(res?.data?.message ?? 'Details Submitted!', {
                        type: 'success',
                    });
                }
            },
        });
    };

    return (
        <div className="flex flex-col gap-4">
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="flex flex-col gap-4"
            >
                {/* Account Holder Name Input */}
                <div className="flex flex-col gap-1">
                    <label>Account Holder Name</label>
                    <input
                        type="text"
                        placeholder="Account Name"
                        className="w-full border rounded h-[38px] focus:outline-none px-2"
                        {...register('accountName', {
                            required: 'Account name is required',
                        })}
                    />
                    {errors.accountName && (
                        <span className="text-red-500">
                            {errors.accountName.message}
                        </span>
                    )}
                </div>

                {/* UPI ID Input */}
                <div className="flex flex-col gap-1">
                    <label>UPI ID</label>
                    <input
                        type="text"
                        placeholder="Enter UPI ID"
                        className="w-full border rounded h-[38px] focus:outline-none px-2"
                        {...register('upiId', {
                            required: 'UPI ID is required',
                        })}
                    />
                    {errors.upiId && (
                        <span className="text-red-500">
                            {errors.upiId.message}
                        </span>
                    )}
                </div>

                {/* Amount Input */}
                <div className="flex flex-col gap-1">
                    <label>Withdraw Amount</label>
                    <div className="flex border rounded">
                        <span className="bg-[#F8F9FA] w-[42px] border-r text-center py-2">
                            ₹
                        </span>
                        <input
                            type="number"
                            placeholder="Amount"
                            className="w-full h-[38px] focus:outline-none ml-2"
                            {...register('amount', {
                                required: 'Amount is required',
                            })}
                        />
                    </div>
                    {errors.amount && (
                        <span className="text-red-500">
                            {errors.amount.message}
                        </span>
                    )}
                </div>

                {/* Submit Button */}
                <button
                    type="submit"
                    className={`p-2 flex justify-center items-center border gap-2 border-[#757575] mx-auto rounded mb-4 w-full text-white ${
                        isLoading ? 'bg-slate-400' : 'bg-green-700'
                    }`}
                    disabled={isLoading || isSubmitting}
                >
                    {isLoading || isSubmitting ? 'Submitting...' : 'Submit'}
                    {(isLoading || isSubmitting) && (
                        <Loader scale={2} color="white" />
                    )}
                </button>
            </form>
        </div>
    );
};

export default WithdrawalUPI;
