export enum BATTLE_STATUS {
    PENDING = 'Pending',
    PLAYING = 'Playing',
    DRAW = 'Draw',
    READY = 'Ready',
    SEMI_CANCELLED = 'Semi Cancelled',
    VIEW = 'View',
    FINISHED = 'Finished',
    CANCELLED = 'Cancelled',
}

export enum RESULT_STATUS {
    WON = 'Won',
    LOST = 'Lost',
    CANCELLED = 'Cancelled',
}

export enum TRANSACTION_TYPE {
    CREDIT = 'CREDIT',
    DEBIT = 'DEBIT',
    CANCEL = 'CANCEL',
}

export enum TRANSACTION_STATUS {
    PENDING = 'Pending',
    APPROVED = 'Approved',
    REJECTED = 'Rejected',
}
