import { useRef, useEffect } from 'react';
import sound from '../../assets/sound.mp3';

export const useSound = () => {
    const soundRef = useRef<HTMLAudioElement | null>(null);

    useEffect(() => {
        // Initialize the audio when the component mounts
        soundRef.current = new Audio(sound);
        // soundRef.current.volume = 1.0;
    }, []);

    // Function to play the sound
    const playSound = () => {
        soundRef.current?.play().catch((error) => {
            console.error('Error playing audio:', error);
        });
    };

    // Function to pause the sound
    const pauseSound = () => {
        soundRef.current?.pause();
    };

    return { playSound, pauseSound };
};
