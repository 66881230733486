import { useMutation, useQuery } from '@tanstack/react-query';
import { userDeleteFetcher } from '../fetcher/fetcher';

type Props = {
    data: any;
    isLoading: boolean;
    error: any;
    mutate: any;
};

const useDeleteBattle = (): Props => {
    const { data, isLoading, mutate, error } = useMutation(
        ['delete-battle'],
        userDeleteFetcher,
        {
            retry: false,
        }
    );

    return {
        data: data,
        isLoading,
        error,
        mutate,
    };
};

export default useDeleteBattle;
