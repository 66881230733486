import axios from 'axios';
import { toast } from 'react-toastify';

export const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getAccessToken = () => {
    return localStorage.getItem('token') || localStorage.getItem('TOKEN');
};

const getInstance = ({ headers: _headers = {} }: { headers?: any }) => {
    const headers: any = {
        clientType: 'client',
        ..._headers,
    };
    const tokenContext = getAccessToken() ? { token: getAccessToken() } : null;
    if (tokenContext) {
        headers['Authorization'] = `Bearer ${tokenContext.token}`;
    }
    const Instance = axios.create({
        baseURL: BASE_URL,
        timeout: 50000,
        headers: headers,
    });

    const logout = () => {
        localStorage.clear();
        window.location.replace('/login');
    };

    Instance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            /* if user is not authenticated then redirect to login page */
            if (error.response && error.response.status === 401) {
                toast('Unauthorized', { type: 'error' });
                logout();
            }
            return Promise.reject(
                error?.response?.data?.error || error?.response
            );
        }
    );
    return Instance;
};

interface Config {
    params?: any;
    headers?: any;
}

export const Client: any = {
    get: (endpoint: string, config: Config = {}) => {
        let params: any = [];
        if (config.params) {
            for (let p in config.params) {
                let key = p;
                if (Array.isArray(config.params[p])) {
                    config.params[p].forEach((element: any) => {
                        params.push(`${key}=${encodeURIComponent(element)}`);
                    });
                } else {
                    params.push(
                        `${key}=${encodeURIComponent(config.params[p])}`
                    );
                }
            }
        }
        const Instance = getInstance({ headers: config.headers || {} });
        return Instance(endpoint, {
            params: config.params || {},
        });
    },
    post: (endpoint: string, data: any, config: Config = {}) => {
        let params: any = [];
        if (config.params) {
            for (let p in config.params) {
                let key = p;
                if (Array.isArray(config.params[p])) {
                    config.params[p].forEach((element: any) => {
                        params.push(`${key}=${encodeURIComponent(element)}`);
                    });
                } else {
                    params.push(
                        `${key}=${encodeURIComponent(config.params[p])}`
                    );
                }
            }
            endpoint = endpoint + (params.length ? '?' + params.join('&') : '');
        }
        const Instance = getInstance({ headers: config.headers || {} });
        return Instance.post(endpoint, data);
    },
    delete: (endpoint: string, data: any, config: Config = {}) => {
        let params: any = [];
        if (config.params) {
            for (let p in config.params) {
                let key = p;
                if (Array.isArray(config.params[p])) {
                    config.params[p].forEach((element: any) => {
                        params.push(`${key}=${encodeURIComponent(element)}`);
                    });
                } else {
                    params.push(
                        `${key}=${encodeURIComponent(config.params[p])}`
                    );
                }
            }
            endpoint = endpoint + (params.length ? '?' + params.join('&') : '');
        }
        const Instance = getInstance({ headers: config.headers || {} });
        return Instance.delete(endpoint, data);
    },

    put: (endpoint: string, data: any, config: Config = {}) => {
        let params: any = [];
        if (config.params) {
            for (let p in config.params) {
                let key = p;
                if (Array.isArray(config.params[p])) {
                    config.params[p].forEach((element: any) => {
                        params.push(`${key}=${encodeURIComponent(element)}`);
                    });
                } else {
                    params.push(
                        `${key}=${encodeURIComponent(config.params[p])}`
                    );
                }
            }
            endpoint = endpoint + (params.length ? '?' + params.join('&') : '');
        }
        const Instance = getInstance({ headers: config.headers || {} });
        return Instance.put(endpoint, data);
    },

    patch: (endpoint: string, data: any, config: Config = {}) => {
        let params: any = [];
        if (config.params) {
            for (let p in config.params) {
                let key = p;
                if (Array.isArray(config.params[p])) {
                    config.params[p].forEach((element: any) => {
                        params.push(`${key}=${encodeURIComponent(element)}`);
                    });
                } else {
                    params.push(
                        `${key}=${encodeURIComponent(config.params[p])}`
                    );
                }
            }
            endpoint = endpoint + (params.length ? '?' + params.join('&') : '');
        }
        const Instance = getInstance({ headers: config.headers || {} });
        return Instance.patch(endpoint, data);
    },
};
