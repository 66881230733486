import { Transition, Dialog } from '@headlessui/react';
import React, { useEffect, useRef, useState } from 'react';
import UploadImg from '../../assets/UploadImg';
import { toast } from 'react-toastify';
import { useUpdatePlayerBattleResponse } from '../../apis/hooks/useUpdatePlayerBattleResponse';
import { useUpdateBattle } from '../../apis/hooks/useBattles';
import { useUserData } from '../../Context/UserContext';
import Loader from '../Common/Loader/Loader';
import { useUploadFiles } from '../../apis/hooks/useUploadFiles';
// import { useSocket } from '../../Context/SocketContext';
import { RESULT_STATUS } from '../../Common/constants';
import { StringSupportOption } from 'prettier';
import { useNavigate, useParams } from 'react-router-dom';

type Props = {
    onClose: () => void;
    isOpen: boolean;
    battle_id: string;
    status: string;
    battleDetails: any;
};

const chipsList = [
    {
        key: 1,
        type: 'No Room Code',
    },
    {
        key: 2,
        type: 'Popular Mode',
    },
    {
        key: 3,
        type: 'Not Joined',
    },
    {
        key: 4,
        type: 'Not Playing',
    },
    {
        key: 5,
        type: "Don't Want To Play",
    },
    {
        key: 6,
        type: 'Opponent Abusing',
    },
    {
        key: 7,
        type: 'Game Not Start',
    },
    {
        key: 8,
        type: 'Other',
    },
];
const UploadImgDialog = ({
    isOpen,
    onClose,
    battle_id,
    status,
    battleDetails,
}: Props) => {
    const navigate = useNavigate();

    const fileInputRef = useRef<any>(null);
    const [fileData, setFileData] = useState<any>({});
    const [previewUrl, setPreviewUrl] = useState('');
    const [reason, setReason] = useState('');
    const [reasonIndex, setReasonIndex] = useState();
    const [description, setDescription] = useState('');
    const { mutate: submitResponse, isLoading } =
        useUpdatePlayerBattleResponse();
    const { mutate: updateStatus, isLoading: updateStatusLoading } =
        useUpdateBattle();
    const { mutate: uploadFile, isLoading: uploading } = useUploadFiles();

    const { user } = useUserData();
    // const { message, socket } = useSocket();

    const handleFileChange = (event: any) => {
        const file = event.target.files[0];
        if (file?.size > 5 * 1024 * 1024) {
            return alert('File Size Must Be smaller then 5MB!');
        }
        if (file) {
            setFileData(file);
            const reader: any = new FileReader();
            reader.onloadend = () => {
                setPreviewUrl(reader?.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const postResults = (status: string) => {
        if (status === RESULT_STATUS.WON) {
            handleFileUpload(fileData);
        } else if (status === RESULT_STATUS.LOST) {
            onSubmitStatus();
        }
    };

    const handleFileUpload = async (file: any) => {
        const formData = new FormData();
        formData.append('file', file);
        await uploadFile(formData, {
            onSuccess: (data: any) => {
                if (data?.data?.id) {
                    onSubmitStatus(data?.data?.id);
                } else {
                    toast(data?.message ?? 'Error while uploading file', {
                        type: 'error',
                    });
                    fileInputRef.current.value = '';
                }
            },
            onError: (error: any) => {
                fileInputRef.current.value = '';
                toast(error?.message ?? 'Error while uploading file', {
                    type: 'error',
                });
            },
        });
    };

    const onSubmitStatus = async (fileId?: string) => {
        let payload: any = {
            battleId: battle_id,
            status: status,
            userId: user?.userdetails?.id,
        };
        if (status === RESULT_STATUS.WON) {
            payload = {
                ...payload,
                fileId: fileId ?? null,
            };
        } else if (status === RESULT_STATUS.LOST) {
            // no new data need
        } else {
            payload = {
                ...payload,
                cancelReason: reason,
                cancelDescription: description,
            };
        }

        submitResponse(payload, {
            onSuccess: (res: any) => {
                if (res?.data?.error) {
                    toast(res?.data?.message ?? 'Unable to update battle', {
                        type: 'error',
                    });
                } else {
                    toast(
                        res?.data?.message ??
                            'Your response is successfully saved',
                        {
                            type: 'success',
                        }
                    );
                    navigate('/battle/Classic/b744r8hyegddoqfng6r9z2hzz');
                }
            },
            onError: (error: any) => {
                toast(
                    Array.isArray(error?.message)
                        ? `${error?.message[0]?.property} is required`
                        : error?.message ?? 'Error while result post',
                    {
                        type: 'error',
                    }
                );
            },
        });
    };

    const selectReason = (res: any) => {
        setReason(res?.type);
        setReasonIndex(res?.key);
    };

    useEffect(() => {
        setFileData(null);
    }, []);
    return (
        <Transition.Root show={isOpen} as={React.Fragment} appear>
            <Dialog as="div" className="relative z-[1000]" onClose={onClose}>
                <Transition.Child
                    as={React.Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 transition-opacity bg-black bg-opacity-50" />
                </Transition.Child>
                <div className="fixed inset-0 z-10 flex items-end justify-center overflow-hidden">
                    <Transition.Child
                        as={React.Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-full"
                        enterTo="opacity-100 translate-y-0"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-full"
                    >
                        <Dialog.Panel className="w-full max-w-lg overflow-hidden transition-all transform bg-white shadow-2xl">
                            <div
                                className={`${!previewUrl ? 'max-h-[70vh]' : 'max-h-[80vh]'} bg-white font-medium`}
                            >
                                <div className="flex items-center justify-end p-4 text-center bg-white">
                                    <button
                                        onClick={onClose}
                                        className="text-xl"
                                    >
                                        &#10005;
                                    </button>
                                </div>
                                {status === RESULT_STATUS.WON ? (
                                    <>
                                        <div className="text-3xl text-[#212529] text-center items-center">
                                            <h1>Upload Result</h1>
                                        </div>
                                        <div className="flex flex-col items-center justify-center gap-4 p-4 mx-auto text-xl rounded">
                                            {previewUrl && (
                                                <div className="flex flex-col items-center gap-2 text-base">
                                                    <button
                                                        className="rounded-lg p-2 px-4
                                                                bg-[#0d6efd] border-[#0d6efd] text-[#fff] hover:bg-[#0b5ed7] hover:border-[#0b5ed7]
                                                        "
                                                        onClick={() =>
                                                            fileInputRef.current.click()
                                                        }
                                                    >
                                                        Replace Image
                                                    </button>
                                                    <input
                                                        ref={fileInputRef}
                                                        type="file"
                                                        accept="image/*"
                                                        required
                                                        hidden
                                                        data-max-size="4096000"
                                                        id="upload_ipt"
                                                        onChange={
                                                            handleFileChange
                                                        }
                                                    />
                                                    <img
                                                        src={previewUrl}
                                                        className="w-[200px] h-[200px] object-fill"
                                                        alt="file"
                                                    />
                                                </div>
                                            )}
                                            {!previewUrl && (
                                                <>
                                                    <button
                                                        className="rounded-lg p-2 w-full
                                                                bg-[#0d6efd] border-[#0d6efd] text-[#fff] hover:bg-[#0b5ed7] hover:border-[#0b5ed7]
                                                        "
                                                        onClick={() =>
                                                            fileInputRef.current.click()
                                                        }
                                                    >
                                                        Upload Image
                                                    </button>
                                                    <input
                                                        ref={fileInputRef}
                                                        type="file"
                                                        accept="image/*"
                                                        className="hidden"
                                                        required
                                                        data-max-size="4096000"
                                                        id="upload_ipt"
                                                        onChange={
                                                            handleFileChange
                                                        }
                                                    />
                                                </>
                                            )}
                                            <button
                                                className={`rounded-lg p-2 mb-2 w-full text-[#fff]
												${isLoading || !fileData || !previewUrl ? 'cursor-not-allowed bg-[#198754]  opacity-50' : 'bg-[#198754] border-[#198754]  hover:bg-[#157347] hover:border-[#146c43] cursor-pointer'}`}
                                                onClick={() =>
                                                    postResults(status)
                                                }
                                                disabled={
                                                    isLoading ||
                                                    !fileData ||
                                                    !previewUrl
                                                }
                                            >
                                                <div className="flex items-center justify-center gap-4">
                                                    {isLoading || uploading
                                                        ? 'Submitting...'
                                                        : 'Post Results'}
                                                    {(isLoading ||
                                                        uploading) && (
                                                        <Loader
                                                            scale={2}
                                                            color="white"
                                                        />
                                                    )}
                                                </div>
                                            </button>
                                        </div>
                                    </>
                                ) : status === RESULT_STATUS.LOST ? (
                                    <div className="px-2">
                                        <div className="text-3xl text-[#212529] text-center items-center">
                                            <h1>
                                                Are You Sure You Lost This Game?
                                            </h1>
                                        </div>
                                        <div className="flex flex-col items-center justify-center gap-4 p-4 mx-auto text-xl rounded">
                                            <button
                                                className="rounded-lg p-2 w-full bg-[#bb2d3b] border-[#bb2d3b] text-[#fff] hover:bg-[#bb2d3b] hover:border-[#bb2d3b] cursor-pointer"
                                                disabled={isLoading}
                                                onClick={() => onSubmitStatus()}
                                            >
                                                <div className="flex items-center justify-center gap-4">
                                                    {isLoading
                                                        ? 'Submitting...'
                                                        : 'Yes, I Lost'}
                                                    {isLoading && (
                                                        <Loader
                                                            scale={2}
                                                            color="white"
                                                        />
                                                    )}
                                                </div>
                                            </button>
                                            <button
                                                className="rounded-lg p-2 w-full border border-[#bb2d3b] hover:text-[#fff] hover:bg-[#bb2d3b] hover:border-[#bb2d3b]"
                                                onClick={onClose}
                                            >
                                                No
                                            </button>
                                        </div>
                                    </div>
                                ) : status === RESULT_STATUS.CANCELLED ? (
                                    <div className="px-2">
                                        <div className="text-2xl text-[#212529] text-center items-center">
                                            <h1>We Would Like To Know More</h1>
                                        </div>
                                        <p className="text-[#212529] text-center items-center">
                                            Select Reason For Cancelling
                                        </p>
                                        <div className="flex flex-col gap-4 p-2 border rounded-md">
                                            <div className="flex flex-wrap gap-2 text-sm">
                                                {chipsList?.map(
                                                    (
                                                        res: any,
                                                        index: number
                                                    ) => {
                                                        return (
                                                            <p
                                                                key={index}
                                                                className={`text-white px-2 py-1 border cursor-pointer rounded-full ${reasonIndex === index + 1 ? 'bg-[#0d6efd]' : 'bg-[#157347]'}`}
                                                                onClick={() =>
                                                                    selectReason(
                                                                        res
                                                                    )
                                                                }
                                                            >
                                                                {res?.type}
                                                            </p>
                                                        );
                                                    }
                                                )}
                                                {reason === 'Other' && (
                                                    <input
                                                        type="text"
                                                        placeholder="Enter Cancel Description"
                                                        className="w-full px-4 py-2 text-base border-2 border-[#198754] rounded-md"
                                                        value={description}
                                                        onChange={(e) =>
                                                            setDescription(
                                                                e?.target?.value
                                                            )
                                                        }
                                                    />
                                                )}
                                            </div>
                                            <button
                                                disabled={isLoading}
                                                className="rounded-lg p-2 mb-2 w-full text-[#fff] bg-[#198754] border-[#198754] hover:bg-[#157347] hover:border-[#146c43] cursor-pointer"
                                                onClick={() => onSubmitStatus()}
                                            >
                                                <div className="flex items-center justify-center gap-4">
                                                    {isLoading
                                                        ? 'Submitting...'
                                                        : 'Post Result'}
                                                    {isLoading && (
                                                        <Loader
                                                            scale={2}
                                                            color="white"
                                                        />
                                                    )}
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default UploadImgDialog;
