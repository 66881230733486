const WhatsappIcon = (props: any) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            // width="25"
            // height="25"
            preserveAspectRatio="xMidYMid"
            viewBox="0 0 256 257"
            {...props}
        >
            <filter id="a" width="200%" height="200%" x="-50%" y="-50%">
                <feGaussianBlur
                    in="SourceGraphic"
                    result="blur"
                    stdDeviation="3"
                ></feGaussianBlur>
            </filter>
            <linearGradient
                id="b"
                x1="49.998%"
                x2="49.998%"
                y1="99.993%"
                y2="-.006%"
            >
                <stop offset="0" stopColor="#20b038"></stop>
                <stop offset="1" stopColor="#60d66a"></stop>
            </linearGradient>
            <path
                fillOpacity="0.24"
                d="M.297 244l17.155-62.594C6.904 163.09 1.282 142.284 1.34 121.014 1.34 54.362 55.588.172 122.24.172c32.34 0 62.71 12.577 85.488 35.412 22.835 22.835 35.412 53.205 35.354 85.488 0 66.651-54.249 120.842-120.9 120.842h-.058c-20.227 0-40.107-5.1-57.784-14.722zm67.057-38.716l3.651 2.203c15.417 9.157 33.094 13.967 51.119 14.025h.058c55.35 0 100.44-45.033 100.44-100.44 0-26.835-10.432-52.046-29.384-71.057-18.952-19.01-44.222-29.442-71.056-29.442-55.408 0-100.499 45.033-100.499 100.44 0 18.953 5.274 37.441 15.359 53.438l2.376 3.825-10.142 37.035z"
                filter="url(#a)"
                transform="translate(6 7)"
            ></path>
            <path
                fill="url(#b)"
                d="M5.781 237.539l16.37-59.7a115.214 115.214 0 01-15.397-57.581c0-63.535 51.744-115.221 115.22-115.221 30.852 0 59.758 12.02 81.508 33.77s33.714 50.714 33.714 81.508c0 63.534-51.744 115.22-115.221 115.22h-.057c-19.29 0-38.236-4.865-55.064-14.023z"
                transform="translate(6 7)"
            ></path>
            <g fill="#fff">
                <path
                    d="M1.603 241.717L18.545 179.9c-10.417-18.087-15.97-38.636-15.912-59.642C2.633 54.434 56.208.916 122.033.916c31.938 0 61.93 12.42 84.426 34.972 22.551 22.552 34.972 52.545 34.915 84.427 0 65.824-53.575 119.342-119.4 119.342h-.056a119.45 119.45 0 01-57.067-14.539zm66.224-38.235l3.606 2.175c15.226 9.044 32.683 13.795 50.485 13.852h.057c54.662 0 99.194-44.474 99.194-99.194 0-26.501-10.303-51.4-29.02-70.174-18.717-18.774-43.673-29.077-70.174-29.077-54.72 0-99.251 44.474-99.251 99.194 0 18.717 5.208 36.976 15.168 52.773l2.347 3.778-10.017 36.575z"
                    transform="translate(6 7)"
                ></path>
                <path
                    d="M92.154 70.289c-2.233-4.98-4.58-5.095-6.697-5.152-1.717-.057-3.72-.057-5.724-.057s-5.209.744-7.956 3.72c-2.748 2.977-10.418 10.189-10.418 24.9 0 14.652 10.704 28.847 12.192 30.85 1.488 2.004 20.663 33.084 50.942 45.047 25.185 9.96 30.337 7.956 35.774 7.441 5.495-.515 17.63-7.212 20.148-14.195 2.461-6.983 2.461-12.936 1.717-14.195-.744-1.26-2.747-2.003-5.724-3.492-2.976-1.488-17.629-8.7-20.376-9.73-2.748-1.03-4.751-1.488-6.697 1.488-2.004 2.976-7.727 9.673-9.445 11.677-1.717 2.003-3.491 2.232-6.468.744-2.976-1.488-12.592-4.637-23.982-14.825-8.872-7.899-14.882-17.687-16.6-20.663-1.717-2.976-.171-4.58 1.317-6.067 1.316-1.317 2.976-3.492 4.465-5.209s2.003-2.976 2.976-4.98c.973-2.003.515-3.72-.229-5.208-.744-1.489-6.582-16.199-9.215-22.094z"
                    transform="translate(6 7)"
                ></path>
            </g>
        </svg>
    );
};

export default WhatsappIcon;
