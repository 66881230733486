import { useMutation, useQuery } from '@tanstack/react-query';
import { Client } from '..';
import { API_PATHS } from '../constants';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

type Props = {
    data: any;
    isLoading: boolean;
    mutate: any;
    error: any;
};

export const useGetWalletLogs = (): Props => {
    const { data, isLoading, mutate, error } = useMutation(
        ['get-user-wallet-logs'],
        (data: any) => {
            return Client.post(API_PATHS.GET_USER_WALLET_LOGS, data);
        }
    );

    return {
        data: data,
        isLoading,
        mutate,
        error,
    };
};

export const useAddWallet = (): Props => {
    const { data, isLoading, mutate, error } = useMutation(
        ['add-wallet'],
        (data: any) => {
            return Client.post(API_PATHS.ADD_WALLET, data);
        }
    );

    return {
        data: data,
        isLoading,
        mutate,
        error,
    };
};

export const useGetActiveUPIPayment = () => {
    const { data, isLoading, error } = useQuery(
        ['get-active-upi'],
        async (data: any) => {
            return await Client.get(API_PATHS.GET_ACTIVE_UPI_PAYMENT);
        }
        //  {
        //      enabled: !!userId,
        //  }
    );

    useEffect(() => {
        if (error) {
            console.log('error', error);

            const message: any = error;
            toast(message?.message, {
                type: 'error',
            });
        }
    }, [error]);

    return {
        data: data?.data?.data ?? null,
        isLoading,
        error,
    };
};
