import { useQuery } from '@tanstack/react-query';
import { API_PATHS } from '../constants';
import { Client } from '..';

export const useGetVisibilityByModule = ({
    userId,
    name,
}: {
    userId: string;
    name: string;
}) => {
    const { data, isLoading, error } = useQuery(
        ['get-visibility'],
        async (data: any) => {
            return await Client.get(
                API_PATHS.GET_VISIBILITY_BY_MODULE.replace('{name}', name)
            );
        },
        {
            enabled: !!userId,
        }
    );

    return {
        data: data?.data?.data ?? null,
        isLoading,
        error,
    };
};
