import React from 'react';
import { Transition, Dialog } from '@headlessui/react';

type Props = {
    onClose: (value: any) => any;
    isOpen: boolean;
};

const Logout = ({ isOpen, onClose }: Props) => {
    return (
        <Transition.Root show={isOpen} as={React.Fragment} appear>
            <Dialog
                as="div"
                className="relative z-[1000]"
                onClose={() => onClose(false)}
            >
                <Transition.Child
                    as={React.Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 transition-opacity bg-black bg-opacity-50" />
                </Transition.Child>
                <div className="fixed inset-0 z-10 flex items-end justify-center overflow-hidden">
                    <Transition.Child
                        as={React.Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-full"
                        enterTo="opacity-100 translate-y-0"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-full"
                    >
                        <Dialog.Panel className="w-full max-w-lg overflow-hidden transition-all transform bg-white shadow-2xl">
                            <div className="overflow-y-auto max-h-[60vh] bg-white p-4 flex flex-col gap-4">
                                <div className="flex items-center justify-between text-center text-xl text-[#212529] bg-white">
                                    <h1 className="font-medium">
                                        Confirm to logout
                                    </h1>

                                    <button
                                        onClick={() => onClose(false)}
                                        className=""
                                    >
                                        &#10005;
                                    </button>
                                </div>
                            </div>
                            <div className="flex flex-col gap-4">
                                <div className="flex items-center justify-between text-center text-2xl text-[#212529] bg-white">
                                    <h1 className="font-semibold">
                                        Are you sure you want to logout?
                                    </h1>
                                </div>
                                <div className="flex flex-col items-center w-full gap-4 text-xl">
                                    <button
                                        className={`w-[90%] rounded-lg p-2 bg-[#198754] text-[#fff] hover:bg-[#157347] hover:border-[#146c43]`}
                                        onClick={() => onClose(true)}
                                    >
                                        Yes, I Confirm
                                    </button>
                                    <button
                                        className={
                                            'w-[90%] rounded-lg p-2 bg-[#dc3545] text-[#fff] hover:bg-[#bb2d3b] hover:border-[#b02a37]'
                                        }
                                        onClick={() => onClose(false)}
                                    >
                                        No
                                    </button>
                                </div>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default Logout;
