import React, { ReactNode } from 'react';
import { Transition, Dialog } from '@headlessui/react';

type DialogModalProps = {
    onClose: () => void;
    isOpen: boolean;
};

const GameRules = (props: DialogModalProps) => {
    const { isOpen, onClose } = props;

    return (
        <Transition.Root show={isOpen} as={React.Fragment} appear>
            <Dialog as="div" className="relative z-[1000]" onClose={onClose}>
                <Transition.Child
                    as={React.Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bottom-0 transition-opacity bg-black bg-opacity-50" />
                </Transition.Child>
                <div className="fixed inset-0 z-10 flex items-end justify-center overflow-hidden">
                    <Transition.Child
                        as={React.Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-full"
                        enterTo="opacity-100 translate-y-0"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-full"
                    >
                        <Dialog.Panel className="w-full max-w-lg overflow-hidden transition-all transform bg-white shadow-2xl">
                            <div className="overflow-y-auto max-h-[50vh] bg-white">
                                <div className="flex items-center justify-between text-center sticky top-0 text-xl gap-4 font-medium text-[#212529] bg-white p-4">
                                    <h1 className="">
                                        Updated Game Rules (from 1 November
                                        2024)
                                    </h1>

                                    <button onClick={onClose} className="">
                                        &#10005;
                                    </button>
                                </div>
                                <ul className="p-4">
                                    <li className="border border-[#dee2e6] p-2">
                                        यदि आप जान बूझकर Autoexit करते है तो भी
                                        आपको 100% Loss कर दिया जायेगा ! यदि
                                        दोनों प्लेयर में किसी की काटी खुली नहीं
                                        तो उसे हम कैंसिल कर सकते है ! कैंसिल
                                        करने से पहले (Ludo App) Game से Left हो
                                        जाए ?
                                    </li>
                                    <li className="border border-[#dee2e6] p-2">
                                        यदि 1 टोकन बाहर है और सामने वाला बंदा
                                        Left हो जाता है तो 25% Loss दिया जायगा !
                                        यदि 2 टोकन बाहर है और सामने वाला बंदा
                                        Left होता है तो 100% Loss कर दिया जायगा
                                        ?
                                    </li>
                                    <li className="border border-[#dee2e6] p-2">
                                        Autoexit में यदि 1 टोकन बाहर है तो गेम
                                        कैंसिल किया जा सकता है लेकिन यदि आपने
                                        गेम जान बूझकर छोड़ा होगा तो आपको Loss ही
                                        दिया जायेगा इसमें अंतिम निर्णय Admin का
                                        होगा ?
                                    </li>
                                    <li className="border border-[#dee2e6] p-2">
                                        यदि आपको लगता है की Opponent ने जान-
                                        बूझकर गेम को Autoexit में छोड़ा है लेकिन
                                        Admin ने कैंसिल कर दिया है तो आपसे
                                        वीडियो प्रूफ माँगा जायेगा इसलिए हर गेम
                                        को रिकॉर्ड करना जरुरी है ! यदि आप वीडियो
                                        प्रूफ नहीं देते है तो गेम रिजल्ट एडमिन
                                        के अनुसार ही अपडेट किया जायेगा चाहे आप
                                        विन हो या गेम कैंसिल हो ?
                                    </li>
                                    <li className="border border-[#dee2e6] p-2">
                                        अगर Game के अंदर कोई हैकिंग या कोई
                                        धोखाधड़ी कर रहा है तो आपसे उस Game कि
                                        वीडियो मांगी जायगी ! इसलिए आप हर एक Game
                                        कि वीडियो रिकॉर्ड करे ?
                                    </li>
                                    <li className="border border-[#dee2e6] p-2">
                                        Game समाप्त होने के 15 मिनट के अंदर
                                        रिजल्ट डालना आवश्यक है अन्यथा Opponent
                                        के रिजल्ट के आधार पर गेम अपडेट कर दिया
                                        जायेगा चाहे आप जीते या हारे और इसमें
                                        पूरी ज़िम्मेदारी आपकी होगी इसमें बाद में
                                        कोई बदलाव नहीं किया जा सकता है ?
                                    </li>
                                    <li className="border border-[#dee2e6] p-2">
                                        Win होने के बाद आप गलत स्क्रीनशॉट डालते
                                        है तो गेम को सीधा Cancel कर दिया जायेगा
                                        इसलिए आप हर Game का स्क्रीनशॉट ले ?
                                        'कैंसिल' रिजल्ट डालने के बाद गेम प्ले
                                        करके जीत जाते है तो उसमे हमारी कोई
                                        ज़िम्मेदारी नहीं होगी अतः गेम कैंसिल करने
                                        के बाद स्टार्ट न करे अन्यथा वो कैंसिल ही
                                        माना जायेगा एक बार रिजल्ट डालने के बाद
                                        बदला नहीं जा सकता है इसलिए सोच समझकर
                                        रिजल्ट पोस्ट करे गलत रिजल्ट डालने पर
                                        पेनल्टी भी लगाई जाएगी चाहे आपने वो गलती
                                        से डाला हो या जान बूझकर ?
                                    </li>
                                    <li className="border border-[#dee2e6] p-2">
                                        Ludo App मैं बाजी चलने से पहले ये ध्यान
                                        रहे कि Game Classic मैं है अगर Game
                                        Classic मैं नहीं है Popular या Quick मैं
                                        है तो गेम से तुरंत Left हो जाए !
                                    </li>
                                    <li className="border border-[#dee2e6] p-2">
                                        अगर गेम पॉपुलर मैं है आप गेम से लेफ्ट
                                        नहीं हुए आपने पूरा गेम खेला तो इसमें
                                        आपकी ज़िम्मेदारी होंगी ?
                                    </li>
                                    <li className="border border-[#dee2e6] p-2">
                                        एक जरूरी सूचना सभी प्लेयर ध्यान रखें आप
                                        किसी को जान बूझकर Popular का Code ने
                                        डाले ! अगर कोई बंदा जान बूझकर ludo
                                        पॉपुलर का कोड डालता है तो उसके अमाउंट
                                        मैं से 50% पेनल्टी लगाई जाएगी ?
                                    </li>
                                    <li className="border border-[#dee2e6] p-2">
                                        OTP किसी को ना दे ! हमारी टीम किसी से
                                        किसी भी काम के लिए OTP नहीं लेती है !
                                        यदि आप किसी को OTP देते है तो उसके
                                        ज़िम्मेदार आप स्वयं होंगे ?
                                    </li>
                                </ul>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default GameRules;
