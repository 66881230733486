import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Loader from '../components/Common/Loader/Loader';

type PrivateRoutesProps = {};

const PrivateRoutes: React.FC<PrivateRoutesProps> = () => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const token = localStorage.getItem('TOKEN') as string;
            if (token && token?.length > 0) {
                setIsAuthenticated(true);
                // dispatch({ type: 'SET_USER' })
            } else {
                localStorage.clear();
                sessionStorage.clear();

                setIsAuthenticated(false);
            }
            setIsLoading(false);
        }
    }, []);

    if (isLoading) {
        return <Loader />;
    }

    return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
