import { useMutation, useQuery } from '@tanstack/react-query';
import { Client } from '../index';
import { API_PATHS } from '../constants';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

export const useGetTransactionHistory = ({
    userId,
    page,
    filterType,
}: {
    userId: string;
    page: number;
    filterType: string;
}) => {
    // const { user } = useUserData();
    const { isLoading, data, refetch, error } = useQuery(
        ['get-all-battle'], // Add the id to the query key
        () => {
            // Use a function to call the API with the id
            return Client.get(API_PATHS.GET_TRANSACTION_HISTORY, {
                headers: {
                    // userId: user?.userdetails?.id ?? '',
                },
                params: {
                    userId,
                    page,
                    filterType,
                },
            }).then((res: any) => {
                return res?.data ?? null; // Return the response data
            });
        },
        {
            enabled: !!userId,
        }
    );

    useEffect(() => {
        if (error) {
            const message: any = error;
            toast(message?.error, {
                type: 'error',
            });
        }
    }, [error]);

    return {
        isLoading,
        data,
        refetch,
    };
};
