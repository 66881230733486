import { useQuery } from '@tanstack/react-query';
import { API_PATHS } from '../constants';
import { Client } from '..';

export const useGetAllBattlesTypes = () => {
    const { isLoading, data, refetch } = useQuery(
        ['get-all-battles-types'], // Add the id to the query key
        () => {
            // Use a function to call the API with the id
            return Client.get(API_PATHS.GET_BATTLE_TYPE);
        }
    );

    return {
        isLoading,
        data: data?.data ?? [],
        refetch,
    };
};
