import { useQuery } from '@tanstack/react-query';
import { getAccountDetailsByUser } from '../fetcher/fetcher';

type Props = {
    data: any;
    isLoading: boolean;
    error: any;
};

const useGetAccountDetails = ({ userId }: { userId: string }): Props => {
    const { data, isLoading, error } = useQuery(
        ['withdrawal-details-data', { userId }],
        getAccountDetailsByUser,
        {
            retry: false,
            enabled: !!userId,
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
};

export default useGetAccountDetails;
