import React, { useEffect, useRef, useState } from 'react';
import Layout from '../Common/Layout/Layout';
import Loader from '../Common/Loader/Loader';
import { useForm } from 'react-hook-form';
import { useGetKyc, useSubmitKYC } from '../../apis/hooks/useKYC';
import { useUploadFiles } from '../../apis/hooks/useUploadFiles';
import { toast } from 'react-toastify';
import { MdClose, MdUpload } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useUserData } from '../../Context/UserContext';
import { IoArrowBack } from 'react-icons/io5';
import AlertSection from '../Common/AlertSection/AlertSection';

interface IFormInput {
    name: string;
    emailId: string;
    dateOfBirth: Date;
    aadharCardNumber: string;
    frontImageId: string;
    backImageId: string;
}

const Kyc = () => {
    const navigate = useNavigate();
    const { user } = useUserData();

    const { mutate: uploadFile, isLoading: uploading } = useUploadFiles();
    const { data: kycData, isLoading: loading } = useGetKyc({
        userId: user?.userdetails?.id,
    });

    const { mutate, isLoading } = useSubmitKYC({
        kycId: kycData?.id,
    });
    const [frontImagePreview, setFrontImagePreview] = useState<string | null>(
        null
    );
    const [backImagePreview, setBackImagePreview] = useState<string | null>(
        null
    );
    const fileInputRef = useRef<any>(null);
    const backfileInputRef = useRef<any>(null);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<IFormInput>({
        mode: 'onSubmit',
    });
    // Patch the form fields with fetched kycData when it becomes available
    // useEffect(() => {
    //     if (kycData) {
    //         setValue('name', kycData.name ?? '');
    //         setValue('emailId', kycData.emailId ?? '');
    //         setValue('dateOfBirth', kycData.dateOfBirth ?? '');
    //         setValue('aadharCardNumber', kycData.aadharCardNumber ?? '');
    //         setValue('frontImageId', kycData.frontImageId ?? '');
    //         setValue('backImageId', kycData.backImageId ?? '');
    //         setFrontImagePreview(
    //             kycData.frontImageId ? kycData.frontImage?.fullUrl : ''
    //         );
    //         setBackImagePreview(
    //             kycData.backImageId ? kycData.backImage?.fullUrl : ''
    //         );
    //     }
    // }, [kycData, setValue]);

    const handleFileUpload = async (
        event: React.ChangeEvent<HTMLInputElement>,
        field: 'frontImageId' | 'backImageId'
    ) => {
        const file: any = event.target.files?.[0];
        const formData = new FormData();
        formData.append('file', file);
        await uploadFile(formData, {
            onSuccess: (data: any) => {
                if (data?.data?.id) {
                    setValue(field, data?.data?.id);
                    if (field === 'frontImageId') {
                        setFrontImagePreview(data?.data?.fullUrl);
                    }
                    if (field === 'backImageId') {
                        setBackImagePreview(data?.data?.fullUrl);
                    }
                    toast('File uploaded successfully', { type: 'success' });
                } else {
                    toast(data?.message ?? 'Error while uploading file', {
                        type: 'error',
                    });
                    if (field === 'frontImageId') {
                        fileInputRef.current.value = '';
                    }
                    if (field === 'backImageId') {
                        backfileInputRef.current.value = '';
                    }
                }
            },
            onError: (error: any) => {
                if (field === 'frontImageId') {
                    fileInputRef.current.value = '';
                }
                if (field === 'backImageId') {
                    backfileInputRef.current.value = '';
                }
                toast('Something went wrong', { type: 'error' });
            },
        });
    };

    const onSubmit = async (formData: any) => {
        const payload = {
            ...formData,
            // aadharCardNumber: Number(formData.aadharCardNumber),
            aadharCardNumber: formData.aadharCardNumber,
            userId: user?.userdetails?.id,
            status: 'Pending',
            description: '',
        };
        mutate(payload, {
            onSuccess: (data: any) => {
                if (data?.data?.success) {
                    // toast('KYC submitted successfully', { type: 'success' });
                    navigate(`/home`);
                } else {
                    toast(data?.data?.message ?? 'KYC submission failed', {
                        type: 'error',
                    });
                }
            },
            onError: (error: any) => {
                toast(
                    Array.isArray(error?.message)
                        ? error?.message[0]
                        : error?.message ?? 'Something went wrong',
                    { type: 'error' }
                );
            },
        });
    };
    return (
        <Layout>
            <div className="w-[90%] my-2 mx-auto shadow-[0_2px_30px_rgb(0,0,0,0.12)] border border-[#0000002d] rounded-lg flex flex-col gap-2">
                <AlertSection
                    alertType="INFO"
                    message="You need to submit a document that shows that you are
                        above 18 years of age and not a resident of Assam,
                        Odisha, Sikkim, Nagaland, Telangana, Andhra Pradesh,
                        Tamil Nadu and Karnataka."
                    className="p-1 text-sm "
                ></AlertSection>
            </div>
            <div className="w-[90%] my-2 mx-auto shadow-[0_8px_30px_rgb(0,0,0,0.12)] border border-[#0000002d] rounded-lg flex flex-col gap-2">
                <div className="p-2 rounded-lg">
                    <div className="flex flex-col gap-2">
                        <div className="bg-[#f8f9fa] flex justify-center items-center p-2 text-[#212529] border rounded-md border-[#0000002d]">
                            KYC Details
                        </div>
                    </div>
                </div>

                {kycData?.id && kycData?.status === 'Rejected' && (
                    <div className="p-2 text-lg">
                        <p className="font-semibold text-center text-red-600">
                            Your KYC has been Rejected.
                        </p>
                        <p>Reason: {kycData?.description ?? ''}</p>
                    </div>
                )}
                {loading ? (
                    <div className="flex flex-col items-center justify-center gap-8 h-[300px] p-2">
                        <Loader scale={4} />
                        <p>Please wait. Loading...</p>
                    </div>
                ) : kycData?.id && kycData?.status === 'Approved' ? (
                    <p className="p-2 text-lg font-semibold text-center text-green-600">
                        Your KYC has been Approved.
                    </p>
                ) : kycData?.id && kycData?.status === 'Pending' ? (
                    <p className="p-2 text-sm text-yellow-600">
                        Your KYC is under process. Please wait for some time.
                    </p>
                ) : (
                    <form className="p-2" onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex flex-col gap-2">
                            <div className="flex flex-col gap-1">
                                <label>Name *</label>
                                <input
                                    type="text"
                                    placeholder="Enter Name"
                                    className={`w-full border rounded h-[38px] focus:outline-none px-2 ${errors.name ? 'border-red-600' : ''}`}
                                    {...register('name', {
                                        required: 'Name is required.',
                                        minLength: 2,
                                    })}
                                />
                                {errors.name && (
                                    <span className="text-xs text-red-600">
                                        {errors.name.message}
                                    </span>
                                )}
                            </div>
                            <div className="flex flex-col gap-1">
                                <label>Email Id *</label>
                                <input
                                    type="text"
                                    placeholder="Enter Email Id"
                                    className={`border rounded p-2 w-full h-[38px] focus:outline-none ${errors.emailId ? 'border-red-600' : ''}`}
                                    {...register('emailId', {
                                        required: 'Email Id is Required.',
                                        pattern: {
                                            value: /^[a-zA-Z0-9._%+-]+@gmail\.com$/,
                                            message:
                                                'Please enter a valid gmail id.',
                                        },
                                    })}
                                />
                                {errors.emailId && (
                                    <span className="text-xs text-red-600">
                                        {errors.emailId.message}
                                    </span>
                                )}
                            </div>
                            <div className="flex flex-col gap-1">
                                <label>Date of Birth *</label>
                                <input
                                    type="date"
                                    placeholder="Enter Date of Birth"
                                    // inputMode="numeric"
                                    className={`border rounded p-2 w-full h-[38px] focus:outline-none ${errors.dateOfBirth ? 'border-red-600' : ''}`}
                                    // maxLength={10}
                                    {...register('dateOfBirth', {
                                        required: 'Date of Birth is required.',
                                    })}
                                />
                                {errors.dateOfBirth && (
                                    <span className="text-xs text-red-600">
                                        {errors.dateOfBirth.message}
                                    </span>
                                )}
                            </div>
                            <div className="flex flex-col gap-1">
                                <label>Aadhar Card Number *</label>
                                <input
                                    type="text"
                                    placeholder="Enter Aadhar Card Number"
                                    inputMode="numeric"
                                    className={`border rounded p-2 w-full h-[38px] focus:outline-none ${errors.aadharCardNumber ? 'border-red-600' : ''}`}
                                    {...register('aadharCardNumber', {
                                        required:
                                            'Aadhar Card Number is required.',
                                        minLength: {
                                            value: 12,
                                            message:
                                                'Aadhar Card Number must be 12 digits.',
                                        },
                                        maxLength: {
                                            value: 12,
                                            message:
                                                'Aadhar Card Number must be 12 digits.',
                                        },
                                        pattern: {
                                            value: /^\d{12}$/, // Regex to ensure only digits
                                            message:
                                                'Aadhar Card Number must be numeric and 12 digits long.',
                                        },
                                    })}
                                />
                                {errors.aadharCardNumber && (
                                    <span className="text-xs text-red-600">
                                        {errors.aadharCardNumber.message}
                                    </span>
                                )}
                            </div>
                            <div className="flex gap-2 my-4">
                                <div>
                                    {!frontImagePreview ? (
                                        <>
                                            <div
                                                className={`flex flex-col items-center w-full p-2 text-sm text-center cursor-pointer border rounded ${errors.frontImageId ? 'border-red-600' : ''} `}
                                                onClick={() =>
                                                    fileInputRef.current.click()
                                                }
                                            >
                                                <MdUpload />
                                                Aadhar Card Front Image *
                                            </div>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                className="hidden"
                                                {...register('frontImageId', {
                                                    required:
                                                        'Front Image is required.',
                                                })}
                                                ref={(e) => {
                                                    register(
                                                        'frontImageId'
                                                    ).ref(e);
                                                    fileInputRef.current = e;
                                                }}
                                                onChange={(e) =>
                                                    handleFileUpload(
                                                        e,
                                                        'frontImageId'
                                                    )
                                                }
                                            />
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    {frontImagePreview && (
                                        <div className="relative">
                                            <img
                                                src={frontImagePreview}
                                                alt="Front Preview"
                                                className="object-cover w-32 h-32 mt-2"
                                            />
                                            <MdClose
                                                className="absolute top-0 right-0 w-6 h-6 p-1 text-white bg-black rounded-full cursor-pointer"
                                                onClick={() =>
                                                    setFrontImagePreview(null)
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                                <div>
                                    {!backImagePreview ? (
                                        <>
                                            <div
                                                className={`flex flex-col items-center w-full p-2 text-sm text-center cursor-pointer border rounded ${errors.backImageId ? 'border-red-600' : ''} `}
                                                onClick={() =>
                                                    backfileInputRef.current.click()
                                                }
                                            >
                                                <MdUpload />
                                                Aadhar Card Back Image *
                                            </div>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                className="hidden"
                                                {...register('backImageId', {
                                                    required:
                                                        'Back Image is required.',
                                                })}
                                                ref={(e) => {
                                                    register('backImageId').ref(
                                                        e
                                                    );
                                                    backfileInputRef.current =
                                                        e;
                                                }}
                                                onChange={(e) =>
                                                    handleFileUpload(
                                                        e,
                                                        'backImageId'
                                                    )
                                                }
                                            />
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    {backImagePreview && (
                                        <div className="relative">
                                            <img
                                                src={backImagePreview}
                                                alt="Back Preview"
                                                className="object-cover w-32 h-32 mt-2"
                                            />
                                            <MdClose
                                                className="absolute top-0 right-0 w-6 h-6 p-1 text-white bg-black rounded-full cursor-pointer"
                                                onClick={() =>
                                                    setBackImagePreview(null)
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            {uploading && (
                                <div className="flex flex-col items-center justify-center gap-2">
                                    <Loader scale={2} color="blue" />
                                    <p className="text-blue-600">
                                        Uploading...
                                    </p>
                                </div>
                            )}
                            <div className="mt-4">
                                <button
                                    type="submit"
                                    disabled={
                                        isLoading ||
                                        kycData?.status === 'Approved' ||
                                        kycData?.status === 'Pending'
                                    }
                                    className={`w-full  text-[#fff] h-[38px] rounded  mt-4 ${kycData?.id && (kycData?.status === 'Approved' || kycData?.status === 'Pending') ? 'cursor-not-allowed bg-indigo-300' : 'bg-indigo-600'}`}
                                >
                                    <div className="flex items-center justify-center gap-4">
                                        {isLoading ? 'Loading...' : 'Submit'}
                                        {isLoading && (
                                            <Loader scale={2} color="white" />
                                        )}
                                    </div>
                                </button>
                            </div>
                        </div>
                    </form>
                )}
            </div>
        </Layout>
    );
};

export default Kyc;
