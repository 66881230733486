import { Client } from '../index';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// const API_BASE_URL = 'https://8c32-103-211-53-225.ngrok-free.app';

export const getUserUrl = (id: string) => `${API_BASE_URL}/api/user/${id}`;
export const getUserSignUpUrl = () => `${API_BASE_URL}/api/auth/signup`;

export const getDeleteUrl = (battle_id: string) =>
    `${API_BASE_URL}/api/battles/${battle_id}`;

export const getOtp = () => `${API_BASE_URL}/api/auth/validate-otp`;

export const withdrawalPostDetails = () =>
    `${API_BASE_URL}/api/account-details`;

export const withdrawalDetailsData = () =>
    `${API_BASE_URL}/api/account-details`;

export const withdrawalDetailsUpdate = (id: string) =>
    `${API_BASE_URL}/api/account-details/${id}`;

export const getAccountDetails = (userId: string) =>
    `${API_BASE_URL}/api/account-details/by-user-id/${userId}`;

export const userFetcher = async (data: any) => {
    const { id, ...payload } = data;
    return Client.get(await getUserUrl(id), { ...payload });
};

export const validateOtp = async (data: any) => {
    const { ...payload } = data;
    return Client.post(await getOtp(), { ...payload });
};

export const userSignUpFetcher = () => {
    return Client.post(getUserSignUpUrl());
};

// export const getProfileRegisterFetcher = (data: any) => {
//     return Client.post(getUserUrl(), data)
//   }

export const userDeleteFetcher = (data: any) => {
    const battle_id = data?.battle_id;
    return Client.delete(getDeleteUrl(battle_id));
};

export const withdrawalPostDetailsFetcher = (data: any) => {
    const { ...payload } = data;
    return Client.post(withdrawalPostDetails, { ...payload });
};

export const withdrawalDetailsDataFetcher = async (data: any) => {
    return Client.get(await withdrawalDetailsData);
};

export const withdrawalDetailsUpdateFetcher = async (data: any) => {
    const { id, ...payload } = data;
    return Client.post(await withdrawalDetailsUpdate(id), { ...payload });
};

export const getAccountDetailsByUser = async ({ queryKey }: any) => {
    const { userId } = queryKey[1];
    return Client.get(getAccountDetails(userId));
};
