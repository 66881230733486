import { useMutation, useQuery } from '@tanstack/react-query';
import { userFetcher } from '../fetcher/fetcher';
import { Client } from '..';
import { API_PATHS } from '../constants';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

type Props = {
    data: any;
    isLoading: boolean;
    mutate: any;
    error: any;
};

const useUser = (): Props => {
    const { data, isLoading, mutate, error } = useMutation(
        ['user-details'],
        userFetcher
    );

    return {
        data: data,
        isLoading,
        mutate,
        error,
    };
};

export default useUser;

export const useUpdateUserDetails = () => {
    const { isLoading, mutate } = useMutation(
        ['update-user-details'],
        (data: any) => {
            return Client.put(
                API_PATHS.UPDATE_USER.replace('{id}', data.id),
                data
            );
        }
    );
    return {
        isLoading,
        mutate,
    };
};

export const useUpdateUserAvatar = ({
    userId,
    avatarId,
}: {
    userId: string;
    avatarId: string;
}) => {
    const { isLoading, mutate } = useMutation(
        ['update-user-details'],
        (data: any) => {
            return Client.put(
                API_PATHS.UPDATE_USER_AVATAR.replace(
                    '{userId}',
                    userId
                ).replace('{avatarId}', avatarId)
            );
        }
    );
    return {
        isLoading,
        mutate,
    };
};

export const useGetReferalData = ({ userId }: { userId: string }) => {
    const { data, isLoading, error } = useQuery(
        ['get-user-referal-data'],
        async (data: any) => {
            return await Client.get(
                API_PATHS.GET_USER_REFERAL_DATA.replace('{id}', userId)
            );
        },
        {
            enabled: !!userId,
        }
    );

    useEffect(() => {
        if (error) {
            toast(
                typeof (error as any)?.message === 'string'
                    ? (error as any).message
                    : 'Unknown error while',
                { type: 'error' }
            );
        }
    }, [error]);

    return {
        data: data?.data?.data ?? null,
        isLoading,
        error,
    };
};
