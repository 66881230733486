import { useMutation } from '@tanstack/react-query';
import { Client } from '..';
import { API_PATHS } from '../constants';

export const useUploadFiles = () => {
    const { isLoading, mutate } = useMutation(['upload-files'], (data: any) => {
        return Client.post(API_PATHS.UPLOAD_FILES, data);
    });
    return {
        isLoading,
        mutate,
    };
};
