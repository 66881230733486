import React, { useState } from 'react';
import Layout from '../../components/Common/Layout/Layout';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const ExpansionPanel = ({
    title,
    content,
}: {
    title: string;
    content: any;
}) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="mb-4 border-b">
            <button
                className="flex items-center justify-between w-full p-4 text-left focus:outline-none"
                onClick={() => setIsOpen(!isOpen)}
            >
                <span className="text-lg font-semibold">{title}</span>
                <span>{isOpen ? <FaChevronUp /> : <FaChevronDown />}</span>
            </button>
            <div
                className={`overflow-scroll transition-all duration-300 ${
                    isOpen ? 'max-h-screen' : 'max-h-0'
                }`}
            >
                <div className="p-4">{content}</div>
            </div>
        </div>
    );
};

const Policy = () => {
    return (
        <Layout>
            <div className="mx-auto mt-8 text-justify ">
                <ExpansionPanel
                    title="Privacy Policy"
                    content={
                        <>
                            <h1 className="text-center">Introduction</h1>
                            <ul className="text-sm">
                                <li>
                                    This Document Explains How MKG MUNKAD GAMING
                                    PRIVATE LIMITED Company(Refered To As The
                                    “MKG PLAYERS”) Collects, Processes, Stores
                                    And/Or Shares Any Personal Data And/Or
                                    Information From Users (Jointly Referred To
                                    As “Information”). By Accessing And/Or Using
                                    The Services You Consent The Collection,
                                    Transfer, Manipulation, Storage, Disclosure
                                    And Other Uses Of Your Information As
                                    Described In This Privacy Policy. If You
                                    Have Any Concerns About Providing Data, Or
                                    Having It Used In Any Manner Permitted In
                                    This Privacy Policy, You Should Not Use The
                                    Services. As Set Out In The Terms And
                                    Conditions Relating The Services (The
                                    “Terms”), You Must Be At Least At Legal Age
                                    (Minimum Age Of 18 Years) To Access And/Or
                                    Use The Services, Or If Legally Possible, To
                                    Access With Your Legal Guardian Consent, Due
                                    Authorization And Agreement With These
                                    Privacy Policy.
                                </li>
                            </ul>
                            <br></br>
                            <h1 className="text-center">
                                The Information Collected
                            </h1>
                            <div>
                                MKG PLAYERS and/or third parties, including but
                                not limited to business partners, advertising
                                networks, analytics, or search information
                                providers, may collect and process the following
                                data about you:
                                <ul className="px-4 list-disc">
                                    <li>
                                        Information that you provide when you
                                        fill in forms when accessing and/or
                                        using the services, or when you create
                                        an account within the services;
                                    </li>
                                    <li>
                                        Details of your use of the services and
                                        the resources that you access;
                                    </li>
                                    <li>
                                        From third parties who hold data about
                                        you and who agree to share this data
                                        with us;
                                    </li>
                                </ul>
                                <br></br>
                                <h1 className="text-center">Log Data</h1>
                                <ul>
                                    <li>
                                        Servers automatically record information
                                        created by your use of the services.
                                        This data may include information such
                                        as your IP address, browser type,
                                        operating system, the referring web
                                        page, pages visited, location, your
                                        mobile carrier, device and application
                                        IDs, search terms, and cookie
                                        information. Log data is received when
                                        you interact with the services. This
                                        data is used to provide the services and
                                        to measure, customize, and improve them.
                                    </li>
                                </ul>
                                <h1>Payment Information</h1>
                                <ul>
                                    <li>
                                        If you make a purchase in the services,
                                        MKG PLAYERS may collect the billing and
                                        financial information necessary to
                                        process the charges; and/or do so on
                                        behalf of the relevant payment service
                                        providers. Purchases of third-party
                                        services are subject to the policies
                                        applicable to such providers.
                                    </li>
                                </ul>
                            </div>
                        </>
                    }
                />
                <ExpansionPanel
                    title="Terms and Conditions"
                    content={
                        <>
                            <h1 className="text-center">1. Terms</h1>
                            <ul className="p-2 list-decimal">
                                <li className="pb-2">
                                    These Terms and Conditions is a legally
                                    binding document and is an electronic record
                                    in the form of an electronic contract formed
                                    under Information Technology Act, 2000 and
                                    rules made thereunder. These Terms and
                                    Conditions must be read in conjunction with
                                    the Terms of Use of the App (hereinafter
                                    referred to as “Terms of Use”) and the
                                    Privacy Policy of the App (hereinafter
                                    referred to as “Privacy Policy”).These Terms
                                    of Service (the “Terms”) are a legal and
                                    binding agreement between users (“Users”)
                                    and MKG PLAYERS (referred to as the “ MKG
                                    PLAYERS”), in relation to all games and
                                    applications made available by MKG PLAYERS
                                    (jointly and interchangeably referred to as
                                    the “Services”), and any information, text,
                                    graphics, video, sound, pictures, and any
                                    other materials appearing, sent, uploaded,
                                    communicated, transmitted or otherwise made
                                    available via the abovementioned Services
                                    (jointly referred to as the “Content”).
                                </li>
                                <li className="pb-2">
                                    By accessing and/or using the Services,
                                    Users agree to be bound by these Terms and
                                    MKG PLAYERS Privacy Policy, as stated on
                                    paragraph 5.
                                </li>
                                <li className="pb-2">
                                    Users state that they are of legal age
                                    (minimum 18 years of age) to access the
                                    Services and Content, or if legally
                                    possible, to access with their legal
                                    guardian consent, due authorization and
                                    agreement with these Terms.
                                </li>
                                <li className="pb-2">
                                    IMPORTANT NOTICE: THE CONTEST FOR STAKES IS
                                    OPEN ONLY TO INDIAN CITIZENS, RESIDING IN
                                    INDIA EXCEPT THE RESIDENTS OF THE INDIAN
                                    STATES OF ASSAM, ODISHA, NAGALAND, SIKKIM,
                                    MEGHALAYA, ANDHRA PRADESH, AND TELANGANA.
                                    MKG PLAYERS DOES NOT OFFER POKER FOR STAKES
                                    WITHIN THE TERRITORY OF GUJARAT. THE
                                    RESIDENTS OF GUJARAT HOWEVER ARE NOT
                                    RESTRICTED FROM PLAYING THE FREE TO PLAY
                                    FORMAT OF POKER, OFFERED BY MKG PLAYERS .
                                    MKG PLAYERS DOES NOT OFFER RUMMY FOR STAKES
                                    WITHIN THE TERRITORY OF KERALA. THE
                                    RESIDENTS OF KERALA HOWEVER ARE NOT
                                    RESTRICTED FROM PLAYING THE FREE TO PLAY
                                    FORMAT OF RUMMY, OFFERED BY MKG MUNKAD.
                                    CITIZENS AND/OR RESIDENTS OF COUNTRIES OTHER
                                    THAN INDIA ARE NOT ELIGIBLE TO PARTICIPATE
                                    IN THE CONTEST. CASUAL IS ALLOWED ACROSS ALL
                                    STATES; THE GAME IS PERMITTED TO BE PLAYED
                                    FOR STAKES IN THE STATES WHICH DO NOT
                                    RESTRICT, PROHIBIT THE SAME, AS HIGHLIGHTED
                                    IN THESE TERMS. IF YOU ARE RESIDING AND/OR
                                    ACCESSING THE APP FROM ANY
                                    REGION/STATE/COUNTRY WHERE THE CONTEST FOR
                                    STAKES IS PROHIBITED OR RESTRICTED BY LAW OR
                                    OTHER REASONS, THEN YOU ARE PROHIBITED FROM
                                    REGISTERING AND PARTICIPATING IN THE SAID
                                    CONTEST. YOU ARE RESPONSIBLE FOR COMPLIANCE
                                    OF ANY LAWS THAT ARE APPLICABLE ON YOUR
                                    COUNTRY/DOMICILE/STATE/RESIDENCE. IN CASE
                                    YOU PARTICIPATE IN THE CONTEST BY
                                    MISREPRESENTATION, THEN MKG PLAYERS SHALL IN
                                    ITS SOLE DISCRETION HAVE THE RIGHT TO
                                    DISQUALIFY YOU AT ANY STAGE OF THE PROCESS,
                                    FORFEIT ANY PRIZE (AS DEFINED BELOW) AND
                                    TAKE LEGAL ACTION AGAINST YOU. IF YOU ARE
                                    FOUND OR SUSPECTED TO BE DEFRAUDING THE
                                    SYSTEMS OF THE CONTEST IN ANY MANNER THEN
                                    YOU SHALL BE DEBARRED FROM PARTICIPATING IN
                                    THE CONTEST AND MKG PLAYERS MAY TAKE LEGAL
                                    ACTION AGAINST YOU. EMPLOYEES OF MKG
                                    PLAYERS, ITS RESPECTIVE HOLDING,
                                    SUBSIDIARIES, ASSOCIATE COMPANIES AND
                                    THIRD-PARTY SERVICE PROVIDERS WHO HAVE BEEN
                                    ENGAGED BY MKG PLAYERS FOR THE DEVELOPMENT,
                                    PROMOTION, ADMINISTRATION OR EXECUTION OF
                                    THE CONTEST, AND THEIR FAMILY/HOUSEHOLD
                                    MEMBERS ARE NOT ELIGIBLE TO PARTICIPATE IN
                                    THE CONTEST. IN CASE OF ANY DISPUTE
                                    REGARDING THE APP OR THE CONTEST, MKG
                                    PLAYERS’S DECISION SHALL BE FINAL AND
                                    BINDING. MKG PLAYERS RESERVES THE RIGHT TO
                                    CHANGE OR MODIFY THIS TERMS AND CONDITIONS
                                    FROM TIME TO TIME, PROSPECTIVELY OR
                                    RETROSPECTIVELY, AT ITS SOLE DISCRETION AND
                                    WITHOUT ANY PRIOR INTIMATION TO YOU. YOU ARE
                                    REQUESTED TO CAREFULLY READ THESE TERMS AND
                                    CONDITIONS FROM TIME TO TIME BEFORE USING
                                    THE APP OR PARTICIPATING IN CONTEST. IT
                                    SHALL BE YOUR RESPONSIBILITY TO CHECK THESE
                                    TERMS AND CONDITIONS PERIODICALLY FOR
                                    CHANGES. MKG PLAYERS MAY REQUIRE YOU TO
                                    PROVIDE YOUR DIRECT OR INDIRECT CONSENT TO
                                    ANY UPDATE IN A SPECIFIED MANNER BEFORE
                                    FURTHER USE OF APP OR PARTICIPATION IN THE
                                    CONTEST. IF NO SUCH SEPARATE CONSENT IS
                                    SOUGHT, YOUR CONTINUED USE OF APP OR
                                    PARTICIPATION IN THE CONTEST, FOLLOWING SUCH
                                    CHANGES, WILL CONSTITUTE YOUR ACCEPTANCE OF
                                    THOSE CHANGES. MKG PLAYERS RESERVES THE
                                    RIGHT TO WITHDRAW OR DISCONTINUE OR
                                    TERMINATE THE CONTEST AT ANY STAGE WITHOUT
                                    PRIOR NOTICE AND WITHOUT ANY LIABILITY
                                    WHATSOEVER TO YOU. THE CONTEST IS VOID WHERE
                                    PROHIBITED BY LAW. IN ANY CONTEST, INCASE OF
                                    A DRAW/TIE, THE FINAL DECISION WOULD BE OF
                                    THE COMPANY. THERE WOULD BE NO REFUNDS IN
                                    ANY SITUATION.
                                </li>
                            </ul>

                            <h1 className="text-center">2. The Services</h1>
                            <ul className="p-2 list-decimal">
                                <li className="pb-2">
                                    MKG PLAYERS gives Users in compliance with
                                    these Terms a personal, royalty-free,
                                    non-assignable, non-exclusive and revocable
                                    limited license to use the software that is
                                    provided as part of the Services. This
                                    license is for the sole purpose of enabling
                                    the own personal private use from Users to
                                    enjoy the Services as provided by MKG
                                    PLAYERS, in the manner according with by
                                    these Terms. The Services may change or
                                    being modified from time to time without
                                    prior notice or communication. Furthermore
                                    MKG PLAYERS may, at its own discretion,
                                    cease totally or partially, and/or
                                    permanently or temporarily the provision of
                                    the Services or Users accounts without prior
                                    notice or communication.
                                </li>
                                <li className="pb-2">
                                    The Services may include advertisements,
                                    which may be targeted to the Content or
                                    information on the Services, queries made
                                    through the Services, or any other
                                    information. The types and extent of
                                    advertising by MKG PLAYERS on the Services
                                    are subject to change. In consideration for
                                    MKG PLAYERS granting you access to and use
                                    of the Services, you agree that MKG PLAYERS
                                    and its third party providers and partners
                                    may place such advertising on the Services
                                    or in connection with the display of Content
                                    or information from the Services whether
                                    submitted by you or others.
                                </li>
                                <li className="pb-2">
                                    When access games included in the Services,
                                    the specific rules, scoring, controls and
                                    guidelines for each game can be found within
                                    the game itself. Such rules are integral
                                    part of the Terms, which Users shall agree
                                    and comply. 2.4. Any charges related to
                                    Users for accessing the Services, including
                                    but not limited to internet connection
                                    and/or mobile or data charges are full
                                    responsibility of such Users.
                                </li>
                            </ul>

                            <h1 className="text-center">3. Content</h1>
                            <ul className="p-2 list-decimal">
                                <li className="pb-2">
                                    Subject to your compliance with these Terms,
                                    MKG PLAYERS grants you a limited,
                                    non-exclusive, non-transferable,
                                    non-sublicensable license to access and view
                                    the Content solely in connection with your
                                    permitted use of the Services and solely for
                                    your personal and non-commercial purposes.
                                    The Content available through the Services
                                    has not been verified or authenticated by
                                    us, and may include inaccuracies or false
                                    information. We make no representations,
                                    warranties, or guarantees in connection with
                                    our Services or any Content on the Services,
                                    relating to the quality, suitability, truth,
                                    accuracy or completeness of any content
                                    contained in the Services. You acknowledge
                                    sole responsibility for and assume all risk
                                    arising from your use or reliance of any
                                    Content.
                                </li>
                            </ul>

                            <h1 className="text-center">4. Users accounts</h1>
                            <ul className="p-2 list-decimal">
                                <li className="pb-2">
                                    Users are responsible for safeguarding the
                                    password that use to access the Services.
                                    MKG PLAYERS encourage Users to use strong
                                    passwords (passwords that use a combination
                                    of upper and lower case letters, numbers and
                                    symbols) with the accounts. MKG PLAYERS
                                    cannot and will not be liable for any loss
                                    or damage arising from Users failure to
                                    comply with the above.
                                </li>
                                <li className="pb-2">
                                    Users agree to take all steps necessary to
                                    protect log in details and keep them secret,
                                    and not give login details to anyone else or
                                    allow anyone else to use their login details
                                    or account, including log in details and
                                    account for any social network or platform
                                    that Users may allow the Services to
                                    interact with. MKG PLAYERS shall not have
                                    any responsibility for the consequences of
                                    failure by Users to these provisions, and
                                    agree to fully compensate MKG PLAYERS for
                                    any losses or harm that may result.
                                    Furthermore, MKG PLAYERS shall not be
                                    responsible for any loss that Users may
                                    suffer as a result of an unauthorized access
                                    to their accounts and/or use of the
                                    Services, and MKG PLAYERS accepts no
                                    responsibility for any losses or harm
                                    resulting from this unauthorized use,
                                    whether fraudulently or otherwise.
                                </li>
                            </ul>

                            <h1 className="text-center">
                                5. Contests, Deals, Levels and In App purchases
                                MKG PLAYERS may conduct promotions, including,
                                without limitation, contests, consolation
                                prizes, Level redemptions. MKG PLAYERS is not
                                involved in any way with the contest. Each
                                promotion may have additional Terms and rules,
                                which will be posted or otherwise made available
                                to you, and for purposes of each Promotion, will
                                be deemed incorporated into and form a part of
                                this agreement.
                            </h1>

                            <ul className="p-2 list-decimal">
                                <li className="pb-2">
                                    Pricing of, Purchasing and Availability of
                                    Levels. MKG PLAYERS rewards its users
                                    certain Levels as per the company’s
                                    discretion and a proprietary algorithm that
                                    factors in multiple variables viz. number of
                                    quizzes played, number of quizzes correctly
                                    answered, etc. MKG PLAYERS Levels has no
                                    monetary value and does not constitute
                                    currency or property of any type. The number
                                    of Levels earned and shown in your Account
                                    does not constitute a real-world balance or
                                    reflect any stored value, but rather
                                    measures the extent of your limited license
                                    to use the Applications alongside exercising
                                    few options such as redemption of Levels for
                                    certain prizes, ability to participate in
                                    certain games upon reaching a minimum Level
                                    requirement. MKG PLAYERS´s Levels cannot be
                                    sold or transferred; however, it can be
                                    redeemed for certain special deals by making
                                    a nominal payment, if applicable, against
                                    those deals. MKG PLAYERS reserves the right
                                    to withdraw the deal anytime during the
                                    tenure of the services without prior
                                    intimation. You acknowledge that you are not
                                    entitled to a refund for any unused Levels
                                    or unused Virtual Items when MKG PLAYERS
                                    stops making an Application available,
                                    whether such action is taken at MKG
                                    PLAYERS´s discretion or due to unforeseen
                                    events.
                                </li>
                                <li>
                                    The price payable by you is the price, if
                                    applicable, indicated in the Application
                                    itself. When you purchase a license to use
                                    our Services, or purchase a subscription to
                                    use an Application, you agree to pay taxes,
                                    levies and any other Statutory/Govt. dues by
                                    whatever name called, where applicable, that
                                    we or our agent assesses on your purchase.
                                    We reserve the right to change the price and
                                    specifications shown in relation to any
                                    Application, any subscription, Levels, deals
                                    and Items etc. We do not offer any returns
                                    or cancellations of purchases unless the
                                    user satisfies any minimum performance
                                    criteria that’s decided by MKG PLAYERS and
                                    mentioned on the App.
                                </li>
                            </ul>
                        </>
                    }
                />
                {/* <ExpansionPanel
                    title="Contact"
                    content={<p>Your contact information goes here...</p>}
                /> */}
            </div>
        </Layout>
    );
};

export default Policy;
