// SocketContext.tsx
import { useEffect, useState, useContext, createContext } from 'react';
import { io, Socket } from 'socket.io-client';

const SocketContext = createContext<any>(null);

export const useSocket = () => {
    return useContext(SocketContext);
};

export const SocketProvider = ({ children }: any) => {
    const [message, setMessage] = useState(null);
    const [walletMessage, setWalletMessage] = useState(null);
    const [socket, setSocket] = useState<Socket | null>(null);
    const [walletSocket, setWalletSocket] = useState<Socket | null>(null);

    useEffect(() => {
        const url: string = process.env.REACT_APP_API_SOCKET_CONNECTION || '';

        console.log(`Socket URL: ${url}`);

        const socketInstance = io(url, {
            transports: ['websocket', 'polling'],
            reconnection: true,
            reconnectionAttempts: 5,
            reconnectionDelay: 1000,
        });

        // const walletSocketInstance = io(`${url}/wallet`, {
        //     transports: ['websocket', 'polling'],
        //     reconnection: true,
        //     reconnectionAttempts: 5,
        //     reconnectionDelay: 1000,
        // });

        const handleMessage = (newMessage: any) => {
            console.log('New message received:', newMessage);
            setMessage(newMessage);
        };

        // const handleWalletMessage = (newMessage: any) => {
        //     console.log('Wallet Socket message received:', newMessage);
        //     setWalletMessage(newMessage);
        // };

        socketInstance.on('connect', () => {
            console.log('Socket connected:', socketInstance.id);
        });

        socketInstance.on('onMessage', handleMessage);

        // walletSocketInstance.on('connect', () => {
        //     console.log('Connected to /wallet:', walletSocketInstance.id);
        // });

        // walletSocketInstance.on('onWalletMessage', handleWalletMessage);

        setSocket(socketInstance);
        // setWalletSocket(walletSocketInstance);

        return () => {
            socketInstance.off('onMessage', handleMessage);
            socketInstance.disconnect();

            // walletSocketInstance.off('onWalletMessage', handleWalletMessage);
            // walletSocketInstance.disconnect();
        };
    }, []);

    return (
        <SocketContext.Provider
            value={{ socket, message, walletMessage, walletSocket }}
        >
            {children}
        </SocketContext.Provider>
    );
};
