import { Transition, Dialog } from '@headlessui/react';
import React from 'react';
import moment from 'moment';
import { TRANSACTION_STATUS } from '../../Common/constants';

type Props = {
    onClose: () => void;
    isOpen: boolean;
    data: any;
};

const WithdrawalDetails = ({ isOpen, onClose, data }: Props) => {
    return (
        <Transition.Root show={isOpen} as={React.Fragment} appear>
            <Dialog as="div" className="relative z-[1000]" onClose={onClose}>
                <Transition.Child
                    as={React.Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 transition-opacity bg-black bg-opacity-50" />
                </Transition.Child>
                <div className="fixed inset-0 z-10 flex items-end justify-center overflow-hidden">
                    <Transition.Child
                        as={React.Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-full"
                        enterTo="opacity-100 translate-y-0"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-full"
                    >
                        <Dialog.Panel className="w-full max-w-lg overflow-hidden transition-all transform bg-white shadow-2xl">
                            <div className="overflow-y-auto max-h-[60vh] bg-white p-4 flex flex-col gap-4">
                                <div className="flex items-center justify-between text-center text-2xl text-[#212529] bg-white">
                                    <h1 className="font-semibold">
                                        Withdrawal Details
                                    </h1>

                                    <button onClick={onClose} className="">
                                        &#10005;
                                    </button>
                                </div>
                                <table>
                                    <tbody>
                                        <tr className="border-b border-[#dee2e6] p-2">
                                            <th className="py-2 text-start">
                                                UPI ID / Ac. Number :
                                            </th>
                                            <td className="py-2 text-end">
                                                {data?.withdraw?.upiId
                                                    ? data?.withdraw?.upiId
                                                    : data?.withdraw
                                                          ?.accountNumber ?? ''}
                                            </td>
                                        </tr>
                                        <tr className="border-b border-[#dee2e6] p-2">
                                            <th className="py-2 text-start">
                                                Amount :
                                            </th>
                                            <td className="py-2 text-end">
                                                {data?.withdraw?.amount}
                                            </td>
                                        </tr>
                                        <tr className="border-b border-[#dee2e6] p-2">
                                            <th className="py-2 text-start">
                                                UTR :
                                            </th>
                                            <td className="text-end">N/A</td>
                                        </tr>
                                        <tr className="border-b border-[#dee2e6] p-2">
                                            <th className="py-2 text-start">
                                                Status :
                                            </th>
                                            <td
                                                className={`py-2 text-end flex justify-end`}
                                            >
                                                <p
                                                    className={`text-white ${data?.withdraw?.status === TRANSACTION_STATUS.PENDING ? 'bg-yellow-700' : data?.withdraw?.status === TRANSACTION_STATUS.APPROVED ? 'bg-green-700' : 'bg-red-700'} w-max px-2`}
                                                >
                                                    {data?.withdraw?.status}
                                                </p>
                                            </td>
                                        </tr>
                                        {data?.withdraw?.status !==
                                            TRANSACTION_STATUS.REJECTED && (
                                            <>
                                                <tr className="border-b border-[#dee2e6] p-2">
                                                    <th className="py-2 text-start">
                                                        Requested Time :
                                                    </th>
                                                    <td className="py-2 text-end">
                                                        {moment(
                                                            data?.withdraw
                                                                ?.created_at
                                                        ).format(
                                                            'DD MMM hh:mm A'
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr className="border-b border-[#dee2e6] p-2">
                                                    <th className="py-2 text-start">
                                                        Processed Time :
                                                    </th>
                                                    <td className="py-2 text-end">
                                                        {moment(
                                                            data?.updated_at
                                                        ).format(
                                                            'DD MMM hh:mm A'
                                                        )}
                                                    </td>
                                                </tr>
                                            </>
                                        )}
                                        {data?.withdraw?.status ===
                                            TRANSACTION_STATUS.REJECTED && (
                                            <tr className="border-b border-[#dee2e6] p-2">
                                                <th className="py-2 text-start">
                                                    Reason :{' '}
                                                </th>
                                                <td className="py-2 text-end">
                                                    {data?.withdraw
                                                        ?.description ?? ''}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default WithdrawalDetails;
