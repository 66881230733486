import { useMutation, useQuery } from '@tanstack/react-query';
import { API_PATHS } from '../constants';
import { Client } from '..';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

export const useCreateOrder = () => {
    const { data, isLoading, mutate, error } = useMutation(
        ['create-payment-order'],
        (data: any) => {
            return Client.post(API_PATHS.CREATE_PAYMENT_ORDER, data);
        }
    );

    useEffect(() => {
        if (error) {
            const message: any = error;
            toast(message?.error, {
                type: 'error',
            });
        }
    }, [error]);

    return {
        data: data,
        isLoading,
        mutate,
        error,
    };
};

export const useGetPaymentOrderStatus = () => {
    const { data, isLoading, mutate, mutateAsync, error } = useMutation(
        ['get-payment-order-status'],
        (data: any) => {
            return Client.post(API_PATHS.GET_PAYMENT_ORDER_STATUS, data);
        }
    );

    useEffect(() => {
        if (error) {
            const message: any = error;
            toast(message?.error, {
                type: 'error',
            });
        }
    }, [error]);

    return {
        data: data,
        isLoading,
        mutate,
        mutateAsync,
        error,
    };
};
