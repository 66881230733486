import { useMutation } from '@tanstack/react-query';
import { withdrawalPostDetailsFetcher } from '../fetcher/fetcher';
import { Client } from '..';
import { API_PATHS } from '../constants';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

type Props = {
    data: any;
    isLoading: boolean;
    error: any;
    mutate: any;
};

const useCreateAccountDetails = (): any => {
    const { data, isLoading, mutate, error } = useMutation(
        ['withdrawal-details-post'],
        // withdrawalPostDetailsFetcher,
        (data: any) => {
            // return withdrawalPostDetailsFetcher(data);
            return Client.post(API_PATHS.CREATE_ACCOUNT_DETAILS, data);
            // .then(
            //     (res: any) => {
            //         return res?.data?.data ?? null;
            //     }
            // );
            // .catch((error: any) => {
            //     return error;
            // });
        }
        // {
        // retry: false,
        // }
    );

    useEffect(() => {
        if (error) {
            toast(
                typeof (error as any)?.message === 'string'
                    ? (error as any).message
                    : 'Unknown error while',
                { type: 'error' }
            );
        }
    }, [error]);

    return {
        data: data,
        isLoading,
        error,
        mutate,
    };
};

export default useCreateAccountDetails;
