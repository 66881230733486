import logo from '../../../assets/Logo.png';

const SideSection = () => {
    return (
        <section className="p-20 w-[60%] mx-auto">
            <img src={logo} className="mx-auto mt-16 w-80" />
            <p className="flex justify-center mx-auto text-[34px] mt-8">
                PLAY & WIN REAL CASH BY LUDO!
            </p>
            <p className="mx-auto text-[24px] mt-16">
                For best experience, open{' '}
                <span className="text-lime-600">www.mkgplayers.com </span>
                on chrome mobile
            </p>
        </section>
    );
};

export default SideSection;
