import { useEffect, useMemo, useState } from 'react';
import walletIcon from '../../assets/wallet.png';
import cancelIcon from '../../assets/cancel.png';
import eye from '../../assets/eye.webp';
import Layout from '../../components/Common/Layout/Layout';
import moment from 'moment';
import { useUserData } from '../../Context/UserContext';
import { useGetTransactionHistory } from '../../apis/hooks/useTransaction';
import Loader from '../../components/Common/Loader/Loader';
import noDataFound from '../../assets/noDataFound.png';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { TRANSACTION_STATUS, TRANSACTION_TYPE } from '../../Common/constants';
import WithdrawalDetails from '../../components/WithdrawalDetails/WithdrawalDetails';

const History = () => {
    const { user } = useUserData();
    const [showWithdrawalDialog, setShowWithdrawalDialog] =
        useState<boolean>(false);
    const [withdrawalDetails, setWithdrawalDetails] = useState<any>();
    const [selectedTab, setSelectedTab] = useState({
        name: 'All',
        index: 1,
        filterType: 'All',
    });
    const [currentPage, setCurrentPage] = useState(1);

    const { data, isLoading, refetch } = useGetTransactionHistory({
        userId: user?.userdetails?.id ?? '',
        page: currentPage,
        filterType: selectedTab.filterType ?? 'All',
    });

    // Re-fetch data when tab or page changes
    useEffect(() => {
        refetch();
    }, [selectedTab, currentPage]);

    // Static tabs array
    const tabs = useMemo(
        () => [
            { name: 'All', index: 1, filterType: 'All' },
            { name: 'Classic', index: 2, filterType: 'Battle' },
            { name: 'Wallet', index: 3, filterType: 'Wallet' },
            { name: 'Referal', index: 4, filterType: 'Referal' },
        ],
        []
    );

    // Handle tab change
    const handleTabChange = (tab: any) => {
        setSelectedTab(tab);
        setCurrentPage(1); // Reset to page 1 when switching tabs
    };

    // Handle pagination
    const handlePageChange = (direction: 'prev' | 'next') => {
        if (direction === 'prev' && currentPage > 1) {
            setCurrentPage((prev) => prev - 1);
        } else if (direction === 'next' && currentPage < data?.totalPages) {
            setCurrentPage((prev) => prev + 1);
        }
    };

    return (
        <Layout>
            <div className="mx-auto mt-[20px] w-[90%]">
                {/* Tab Navigation */}
                <div className="flex items-center justify-start gap-2 overflow-x-auto tabs-container whitespace-nowrap">
                    {tabs.map((tab) => (
                        <button
                            key={tab.index}
                            className={`py-2 px-6 text-xs font-bold text-center rounded-full ${
                                selectedTab.index === tab.index
                                    ? 'text-white bg-[#0d6efd]'
                                    : 'border text-[#000]'
                            }`}
                            onClick={() => handleTabChange(tab)}
                        >
                            {tab?.name ?? ''}
                        </button>
                    ))}
                </div>

                <div className="py-5">
                    {isLoading ? (
                        <div className="flex flex-col gap-8 items-center justify-center h-[300px]">
                            <Loader scale={4} />
                            <p>Please Wait. Loading...</p>
                        </div>
                    ) : data?.data?.length === 0 ? (
                        <div className="py-20 text-center">
                            <img src={noDataFound} alt="No Data" height={200} />
                        </div>
                    ) : (
                        <div className="py-5">
                            {data?.data?.map((transaction: any) => (
                                <div
                                    key={transaction.id}
                                    className="flex flex-col gap-2 py-2"
                                >
                                    <>
                                        {transaction?.battle?.id ? (
                                            <div className="flex">
                                                <div className="text-sm border-b border-r w-[90px]">
                                                    <img
                                                        src={cancelIcon}
                                                        alt="Cancel"
                                                    />
                                                    <p>
                                                        {moment(
                                                            transaction?.updated_at
                                                        ).format(
                                                            'DD MMM hh:mm A'
                                                        )}
                                                    </p>
                                                </div>
                                                <div className="flex flex-col justify-center w-full p-2 border-b">
                                                    <div className="flex justify-between text-sm font-medium">
                                                        <p>
                                                            {transaction
                                                                ?.description
                                                                ?.length > 40
                                                                ? transaction?.description.slice(
                                                                      0,
                                                                      40
                                                                  ) + '...'
                                                                : transaction?.description}
                                                        </p>
                                                        <p
                                                            className={`${
                                                                transaction?.type ===
                                                                TRANSACTION_TYPE.CREDIT
                                                                    ? 'text-green-600'
                                                                    : 'text-red-600'
                                                            }`}
                                                        >
                                                            {transaction?.type ===
                                                            TRANSACTION_TYPE.CREDIT
                                                                ? '(+)'
                                                                : '(-)'}
                                                            &nbsp;
                                                            {
                                                                transaction?.amount
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className="flex justify-between text-xs">
                                                        <p>
                                                            Room Code:{' '}
                                                            {
                                                                transaction
                                                                    ?.battle
                                                                    ?.roomCode
                                                            }
                                                        </p>
                                                        <p>
                                                            Balance:{' '}
                                                            {transaction?.balance?.toFixed(
                                                                2
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : transaction?.withdraw?.id ? (
                                            <div className="flex">
                                                <div className="text-sm border-b border-r w-[90px]">
                                                    <div
                                                        className={`relative flex items-center justify-center text-xs font-semibold text-white ${transaction?.status === TRANSACTION_STATUS.PENDING ? 'bg-yellow-700' : transaction?.status === TRANSACTION_STATUS.APPROVED ? 'bg-green-700' : 'bg-red-700'} rounded-lg right-2`}
                                                    >
                                                        {transaction?.status}
                                                    </div>
                                                    <p>
                                                        {moment(
                                                            transaction?.updated_at
                                                        ).format(
                                                            'DD MMM hh:mm A'
                                                        )}
                                                    </p>
                                                </div>
                                                <div className="flex flex-col justify-center w-full p-2 border-b">
                                                    <div className="flex justify-between text-sm font-medium">
                                                        <div className="flex items-center gap-1 text-center">
                                                            <p>
                                                                {transaction
                                                                    ?.description
                                                                    ?.length >
                                                                40
                                                                    ? transaction?.description.slice(
                                                                          0,
                                                                          40
                                                                      ) + '...'
                                                                    : transaction?.description}
                                                            </p>
                                                            <img
                                                                src={eye}
                                                                className="w-[24px] cursor-pointer"
                                                                alt="eye"
                                                                onClick={() => {
                                                                    setWithdrawalDetails(
                                                                        transaction
                                                                    );
                                                                    setShowWithdrawalDialog(
                                                                        true
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                        <p
                                                            className={`${
                                                                transaction?.type ===
                                                                TRANSACTION_TYPE.CREDIT
                                                                    ? 'text-green-600'
                                                                    : 'text-red-600'
                                                            }`}
                                                        >
                                                            {transaction?.type ===
                                                            TRANSACTION_TYPE.CREDIT
                                                                ? '(+)'
                                                                : '(-)'}
                                                            &nbsp;
                                                            {
                                                                transaction?.amount
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className="flex justify-between text-xs">
                                                        <p>
                                                            UTR:{' '}
                                                            {
                                                                // transaction
                                                                //     ?.withdraw
                                                                //     ?.id
                                                            }
                                                        </p>
                                                        {/* <p>
                                                            Status:{' '}
                                                            {
                                                                transaction?.status
                                                            }
                                                        </p> */}
                                                        <p>
                                                            Balance:{' '}
                                                            {transaction?.balance?.toFixed(
                                                                2
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : transaction?.penalty?.id ? (
                                            <div className="flex">
                                                <div className="text-sm border-b border-r w-[90px]">
                                                    <img
                                                        src={cancelIcon}
                                                        alt="Wallet"
                                                    />
                                                    <p>
                                                        {moment(
                                                            transaction?.updated_at
                                                        ).format(
                                                            'DD MMM hh:mm A'
                                                        )}
                                                    </p>
                                                </div>
                                                <div className="flex flex-col justify-center w-full p-2 border-b">
                                                    <div className="flex justify-between text-sm font-medium">
                                                        <p>
                                                            {transaction
                                                                ?.description
                                                                ?.length > 40
                                                                ? transaction?.description.slice(
                                                                      0,
                                                                      40
                                                                  ) + '...'
                                                                : transaction?.description}
                                                        </p>
                                                        <p
                                                            className={`${
                                                                transaction?.type ===
                                                                TRANSACTION_TYPE.CREDIT
                                                                    ? 'text-green-600'
                                                                    : 'text-red-600'
                                                            }`}
                                                        >
                                                            {transaction?.type ===
                                                            TRANSACTION_TYPE.CREDIT
                                                                ? '(+)'
                                                                : '(-)'}
                                                            &nbsp;
                                                            {
                                                                transaction?.amount
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className="flex justify-between text-xs">
                                                        <p>
                                                            UTR:{' '}
                                                            {
                                                                transaction?.utr_number
                                                            }
                                                        </p>
                                                        <p>
                                                            Balance:{' '}
                                                            {transaction?.balance?.toFixed(
                                                                2
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : transaction?.referal?.id ? (
                                            <div className="flex">
                                                <div className="text-sm border-b border-r w-[90px]">
                                                    <img
                                                        src={walletIcon}
                                                        alt="Wallet"
                                                    />
                                                    <p>
                                                        {moment(
                                                            transaction?.updated_at
                                                        ).format(
                                                            'DD MMM hh:mm A'
                                                        )}
                                                    </p>
                                                </div>
                                                <div className="flex flex-col justify-center w-full p-2 border-b">
                                                    <div className="flex justify-between text-sm font-medium">
                                                        <p>
                                                            {transaction
                                                                ?.description
                                                                ?.length > 40
                                                                ? transaction?.description.slice(
                                                                      0,
                                                                      40
                                                                  ) + '...'
                                                                : transaction?.description}
                                                        </p>
                                                        <p
                                                            className={`${
                                                                transaction?.type ===
                                                                TRANSACTION_TYPE.CREDIT
                                                                    ? 'text-green-600'
                                                                    : 'text-red-600'
                                                            }`}
                                                        >
                                                            {transaction?.type ===
                                                            TRANSACTION_TYPE.CREDIT
                                                                ? '(+)'
                                                                : '(-)'}
                                                            &nbsp;
                                                            {
                                                                transaction?.amount
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className="flex justify-between text-xs">
                                                        <p>
                                                            UTR:{' '}
                                                            {
                                                                transaction?.utr_number
                                                            }
                                                        </p>
                                                        <p>
                                                            Balance:{' '}
                                                            {transaction?.balance?.toFixed(
                                                                2
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : transaction?.wallet?.id ? (
                                            <div className="flex">
                                                <div className="text-sm border-b border-r w-[90px]">
                                                    <img
                                                        src={walletIcon}
                                                        alt="Wallet"
                                                    />
                                                    <p>
                                                        {moment(
                                                            transaction?.updated_at
                                                        ).format(
                                                            'DD MMM hh:mm A'
                                                        )}
                                                    </p>
                                                </div>
                                                <div className="flex flex-col justify-center w-full p-2 border-b">
                                                    <div className="flex justify-between text-sm font-medium">
                                                        <p>
                                                            {transaction
                                                                ?.description
                                                                ?.length > 40
                                                                ? transaction?.description.slice(
                                                                      0,
                                                                      40
                                                                  ) + '...'
                                                                : transaction?.description}
                                                        </p>
                                                        <p
                                                            className={`${
                                                                transaction?.type ===
                                                                TRANSACTION_TYPE.CREDIT
                                                                    ? 'text-green-600'
                                                                    : 'text-red-600'
                                                            }`}
                                                        >
                                                            {transaction?.type ===
                                                            TRANSACTION_TYPE.CREDIT
                                                                ? '(+)'
                                                                : '(-)'}
                                                            &nbsp;
                                                            {
                                                                transaction?.amount
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className="flex justify-between text-xs">
                                                        <p>
                                                            UTR:{' '}
                                                            {
                                                                transaction?.utr_number
                                                            }
                                                        </p>
                                                        {transaction?.status ===
                                                        'Approved' ? (
                                                            <p>
                                                                Balance:{' '}
                                                                {
                                                                    transaction?.balance
                                                                }
                                                            </p>
                                                        ) : (
                                                            <p>
                                                                Status:{' '}
                                                                {
                                                                    transaction?.status
                                                                }
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null}
                                    </>
                                </div>
                            ))}

                            {/* Pagination Controls */}
                            <div className="flex justify-center gap-1 text-white">
                                <button
                                    className={`bg-[#0d6efd] hover:bg-[#0b5ed7] cursor-pointer flex-1 flex justify-center text-center p-1 rounded-s-xl ${
                                        currentPage === 1 ? 'opacity-50' : ''
                                    }`}
                                    onClick={() => handlePageChange('prev')}
                                    disabled={currentPage === 1}
                                >
                                    <IoIosArrowBack color="white" size={20} />
                                </button>
                                <div className="bg-[#0d6efd] hover:bg-[#0b5ed7] p-1 w-28 text-center">
                                    {currentPage}
                                </div>
                                <button
                                    className={`bg-[#0d6efd] hover:bg-[#0b5ed7] cursor-pointer flex-1 flex justify-center text-center p-1 rounded-e-xl ${
                                        currentPage === data?.totalPages
                                            ? 'opacity-50'
                                            : ''
                                    }`}
                                    onClick={() => handlePageChange('next')}
                                    disabled={currentPage === data?.totalPages}
                                >
                                    <IoIosArrowForward
                                        color="white"
                                        size={20}
                                    />
                                </button>
                            </div>
                        </div>
                    )}
                </div>

                {showWithdrawalDialog && (
                    <WithdrawalDetails
                        onClose={() => setShowWithdrawalDialog(false)}
                        isOpen={true}
                        data={withdrawalDetails}
                    />
                )}
            </div>
        </Layout>
    );
};

export default History;
