import { useQuery } from '@tanstack/react-query';
import { Client } from '../index';
import { API_PATHS } from '../constants';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

export const usePaymentMethod = () => {
    const { isLoading, data, refetch, error } = useQuery(
        ['get-all-payment-methods'], // Add the id to the query key
        () => {
            // Use a function to call the API with the id
            return Client.get(API_PATHS.GET_PAYMENT_METHOD);
        }
    );

    useEffect(() => {
        if (error) {
            const message: any = error;
            toast(message?.error, {
                type: 'error',
            });
        }
    }, [error]);

    return {
        data: data?.data?.data ?? null,
        isLoading,
        refetch,
    };
};
