import WalletBoxes from '../../components/WalletBoxes/WalletBoxes';
import Layout from '../../components/Common/Layout/Layout';

const Wallet = () => {
    return (
        <Layout>
            <WalletBoxes />
        </Layout>
    );
};

export default Wallet;
