const Telegram = () => {
    return (
        <svg
            height="25"
            preserveAspectRatio="xMidYMid"
            viewBox="0 0 256 256"
            width="25"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="m128 0c-70.693 0-128 57.307-128 128 0 70.693 57.307 128 128 128 70.693 0 128-57.307 128-128 0-70.693-57.307-128-128-128z"
                fill="#40b3e0"
            />
            <path
                d="m190.2826 73.6308-22.862 115.267s-3.197 7.994-11.99 4.157l-52.758-40.448-19.184-9.272-32.294-10.872s-4.956-1.758-5.436-5.595c-.479-3.837 5.596-5.915 5.596-5.915l128.376-50.36s10.552-4.636 10.552 3.038"
                fill="#fff"
            />
            <path
                d="m98.6178 187.6035s-1.54-.144-3.459-6.22c-1.918-6.075-11.67-38.049-11.67-38.049l77.537-49.24s4.477-2.718 4.317 0c0 0 .799.479-1.599 2.717-2.398 2.239-60.911 54.836-60.911 54.836"
                fill="#d2e5f1"
            />
            <path
                d="m122.9015 168.1154-20.868 19.026s-1.631 1.238-3.416.462l3.996-35.341"
                fill="#b5cfe4"
            />
        </svg>
    );
};

export default Telegram;
