import React, { ReactNode } from 'react';
import { Transition, Dialog } from '@headlessui/react';

type DialogModalProps = {
    onClose: () => void;
    isOpen: boolean;
};

const HowPlayVideo = (props: DialogModalProps) => {
    const { isOpen, onClose } = props;

    return (
        <Transition.Root show={isOpen} as={React.Fragment} appear>
            <Dialog as="div" className="relative z-[1000]" onClose={onClose}>
                <Transition.Child
                    as={React.Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bottom-0 transition-opacity bg-gray-500 bg-opacity-25" />
                </Transition.Child>
                <div
                    className={`fixed inset-0 z-10 overflow-y-auto flex flex-col justify-end items-center`}
                >
                    <Transition.Child
                        as={React.Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel
                            className={`mx-auto transform overflow-hidden shadow-2xl ring-1 ring-black ring-opacity-5 transition-all bg-white p-4 w-full`}
                        >
                            <div>
                                <div className="flex justify-between font-semibold text-[20px] ">
                                    <p>How To Play Games & Earn?</p>
                                    <div
                                        onClick={onClose}
                                        className="cursor-pointer"
                                    >
                                        &#10005;
                                    </div>
                                </div>
                                <iframe
                                    className="w-full mt-6 h-[400px]"
                                    // src="https://www.youtube.com/embed/t0Q2otsqC4I"
                                    // src="https://khelo-players.blr1.cdn.digitaloceanspaces.com/uploads/WhatsApp%20Video%202024-07-27%20at%2012.52.17%20AM.mp4"
                                    // src="https://khelo-players.blr1.digitaloceanspaces.com/uploads/WhatsApp%20Video%202024-07-27%20at%2012.52.17%20AM.mp4"
                                    src="https://khelo-players.blr1.cdn.digitaloceanspaces.com/uploads/WhatsApp%20Video%202024-08-11%20at%209.03.32%20PM.mp4"
                                ></iframe>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default HowPlayVideo;
