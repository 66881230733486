import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSignIn } from '../../apis/hooks/useAuth';
import Layout from '../Common/Layout/Layout';
import Loader from '../Common/Loader/Loader';

const Login = () => {
    const navigate = useNavigate();
    const [mobileNumber, setMobileNumber] = useState('');
    const { mutate: userLogin, isLoading } = useSignIn();

    const onSubmit = async () => {
        const payload = {
            mobileNumber: mobileNumber,
        };

        if (!payload.mobileNumber) {
            toast('Please enter mobile number', {
                type: 'error',
            });
            return;
        }

        if (payload.mobileNumber.toString().trim().length !== 10) {
            toast('Please enter valid mobile number', {
                type: 'error',
            });
            return;
        }
        userLogin(payload, {
            onSuccess: (res: any) => {
                if (res?.data?.error) {
                    toast(res?.data?.message ?? 'Unable to login', {
                        type: 'error',
                    });
                }
                if (res?.data?.message) {
                    toast(res?.data?.message ?? 'OTP sent successfully', {
                        type: 'success',
                    });
                    localStorage.setItem('mobileNumber', mobileNumber);
                    navigate('/otp');
                }
            },
            onError: (error: any) => {
                toast(error?.message ?? 'Error occurred during User Login', {
                    type: 'error',
                });
            },
        });
    };

    return (
        <Layout>
            <section className="w-[90%] border rounded  mt-[20px] mx-auto">
                <div className="bg-[#F8F9FA] py-2 px-4 flex justify-center border-b">
                    <span>Login</span>
                </div>
                <div className="p-4 mx-auto ">
                    <span className="mb-8">Mobile Number</span>
                    <div className="flex my-4 border rounded">
                        <span className="bg-[#F8F9FA] w-[42px] h-[38px] border-r flex justify-center pt-2">
                            📞
                        </span>
                        <input
                            type="tel"
                            inputMode="numeric"
                            className="w-full h-[38px] p-2 focus:outline-none"
                            maxLength={10}
                            onChange={(e: any) =>
                                setMobileNumber(e.target.value.trim())
                            }
                        />
                    </div>
                    <div>
                        <span className="mx-auto text-center text-[12.8px] mb-4 font-normal">
                            By Continuing, you agree to our
                            <u className="text-indigo-600 "> Legal Terms</u> and
                            you are 18 years or older.
                        </span>
                        <button
                            disabled={isLoading}
                            className="w-full bg-indigo-600 text-[#fff] h-[38px] rounded mt-2"
                            onClick={() => onSubmit()}
                        >
                            {isLoading ? (
                                <div className="flex items-center justify-center gap-2 p-2">
                                    <Loader scale={2} color="white" />
                                    <p className="text-white">Sending OTP...</p>
                                </div>
                            ) : (
                                'GET OTP'
                            )}
                        </button>
                        <span className="flex justify-center gap-2 text-center text-[12.8px] mb-4 font-normal mt-4 cursor-pointer">
                            Don't have an account ?{' '}
                            <u
                                className="text-indigo-600 md:ml-2"
                                onClick={() => navigate('/register')}
                            >
                                {' '}
                                Register{' '}
                            </u>
                        </span>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default Login;
