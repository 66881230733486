import { useMutation, useQuery } from '@tanstack/react-query';
import { userFetcher, validateOtp } from '../fetcher/fetcher';

type Props = {
    data: any;
    isLoading: boolean;
    mutate: any;
    error: any;
};

const useValidateOtp = (): Props => {
    const { data, isLoading, mutate, error } = useMutation(
        ['validate-otp'],
        validateOtp
    );

    return {
        data: data,
        isLoading,
        mutate,
        error,
    };
};

export default useValidateOtp;
