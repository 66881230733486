const UploadImg = () => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 17 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.26953 4.97213V13.5451H7.73481V4.97213L5.24626 7.46068L4.16121 6.37563L8.50217 2.03467L12.8431 6.37563L11.7581 7.46068L9.26953 4.97213ZM3.13064 15.0798H13.8737C14.0772 15.0798 14.2724 15.1607 14.4163 15.3046C14.5602 15.4485 14.6411 15.6437 14.6411 15.8472C14.6411 16.0507 14.5602 16.2459 14.4163 16.3898C14.2724 16.5337 14.0772 16.6145 13.8737 16.6145H3.13064C2.92713 16.6145 2.73194 16.5337 2.58804 16.3898C2.44413 16.2459 2.36328 16.0507 2.36328 15.8472C2.36328 15.6437 2.44413 15.4485 2.58804 15.3046C2.73194 15.1607 2.92713 15.0798 3.13064 15.0798Z"
                fill="black"
            />
        </svg>
    );
};

export default UploadImg;
