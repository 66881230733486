import { RiProhibited2Line } from 'react-icons/ri';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { IoWarningOutline } from 'react-icons/io5';

const AlertSection = ({
    message,
    alertType,
    className,
    iconSize = 24,
}: {
    message: string;
    alertType: 'PROHIBITED' | 'WARNING' | 'INFO';
    className: string;
    iconSize?: number;
}) => {
    return (
        <div className={`${className} flex gap-2 items-center rounded-md`}>
            <div>
                {alertType === 'PROHIBITED' && (
                    <RiProhibited2Line size={iconSize} />
                )}
                {alertType === 'WARNING' && (
                    <IoWarningOutline size={iconSize} />
                )}
                {alertType === 'INFO' && (
                    <IoInformationCircleOutline size={iconSize} />
                )}
            </div>
            <p>{message}</p>
        </div>
    );
};

export default AlertSection;
