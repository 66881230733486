import React, { useEffect, useState } from 'react';
import cn from 'clsx';
// import { Loader } from '@corecomponents/ui'
// import { HEADER_TYPE } from '@utils/enum'

import Loader from '../Loader/Loader';
import Sidebar from '../Sidebar/Sidebar';
import SideSection from '../SideSection/SideSection';

const Loading = () => (
    <div className="flex items-center justify-center p-3 text-center w-80 h-80">
        <Loader />
    </div>
);

const dynamicProps = {
    loading: Loading,
    ssr: false,
};

// const Sidebar = React.lazy(() => import('../Sidebar/Sidebar'))
const Header = React.lazy(() => import('../PlayerHeader'));

export interface LayoutProps {
    isProtected?: boolean;
    variant?: 'Normal' | 'NoPadding';
    children?: React.ReactNode;
    background?: any;
    hideCohort?: boolean;
}

const LayoutWrapper: React.FC<LayoutProps> = ({ children }) => {
    const [mobile, setMobile] = useState(false);
    const [dataSet, setDataSet] = useState('');

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth < 1024) {
                setMobile(true);
            } else {
                setMobile(false);
            }
        }
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const url = window.location.pathname;
        const dataSet = url.split('/');
        setDataSet(dataSet[1]);
    }, []);

    return (
        <>
            <div
                className={cn(
                    `h-screen mx-auto transition-colors duration-150 flex`
                )}
            >
                <div
                    className={
                        'flex flex-1 flex-col max-w-[100vw] border-r-2 border-[#757575]'
                    }
                >
                    {dataSet === 'home' && (
                        <header className="sticky top-0 overflow-hidden text-white bg-red-600 whitespace-nowrap ">
                            <p className="items-center p-2 text-sm text-center md:text-lg">
                                Commission: 5% ◉ Referral: 2% For All Games
                            </p>
                        </header>
                    )}
                    <Header isPwlogoRequired={true} mobile={mobile} />

                    {children}
                </div>
                {!mobile && <SideSection />}
            </div>
        </>
    );
};

export default LayoutWrapper;
