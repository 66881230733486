import React from 'react';
import cn from 'clsx';
import LayoutWrapper, { LayoutProps } from './LayoutWrapper';
import WhatsappSupport from '../WhatsappSupport/WhatsappSupport';
import { useUserData } from '../../../Context/UserContext';
// import LayoutWrapper, {
//   LayoutProps,
// } from "@components/Common/Layout/LayoutWrapper";

const Layout: React.FC<LayoutProps> = ({ children, ...props }) => {
    const { user } = useUserData();
    return (
        <LayoutWrapper {...props}>
            <main
                className={cn(
                    'fit flex-1 overflow-y-auto relative overflow-x-hidden',
                    {
                        ['py-3 md:py-6']: props.variant !== 'NoPadding',
                    }
                )}
            >
                {children}
                {user?.userdetails?.id ? <WhatsappSupport /> : null}
            </main>
        </LayoutWrapper>
    );
};
export default Layout;
